import * as actionTypes from './actionTypes';
import attachmentApi from '../api/attachmentApi';
import maintenanceRequestApi from '../api/maintenanceRequestApi';


export function setMaintenanceRequestFormInitialValues (values) {
  return function(dispatch) {
    dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_SET_INITIAL_VALUES, payload: values });
  }
}

export function inputChanged (value, identifier) {
  return function(dispatch) {
    dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_INPUT_CHANGED, payload: {
      value : value,
      identifier : identifier
    } });
  }
}

export function sendMaintenanceRequestForm(formData) {

  // make async call to api, handle promise, dispatch action when promise is resolved
  return function(dispatch) {

    dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_SEND});

    return maintenanceRequestApi.sendMaintenanceRequestForm(formData).then(payload => {

      // return lisätty 8.2019
      return dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_SENT_SUCCESS, payload});

    }).catch(error => {
      
      console.log("ERROR: ", error);
      return dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_SENT_ERROR, error});
      
    });
  };
}

// Huoltopyyntö - liitteet upload
export function uploadMaintenanceFormAttachment(attachments) {

  const fd = new FormData();
  for (let index = 0; index < attachments.length; index++) {
      fd.append('attachments[]', attachments[index]);
  }


  return function(dispatch) {
    dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS});
    return attachmentApi.uploadAttachment_api(fd, dispatch, 'MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_PROGRESS').then(payload => {
       return dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_SUCCESS, payload});
    }).catch(error => {
      console.log("ERROR: ", error);
      return dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_ERROR, error});
      
    });
  };
}

// Huoltopyyntö - liitteet delete
export function deleteMaintenanceRequestFormAttachment(id) {

  return function(dispatch) {

    dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT, id});

    return attachmentApi.deleteAttachment_api(id).then(payload => {

      return dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_SUCCESS, payload});

    }).catch(error => {
      // Selaimet valittaa, jos tässä returnin sijasta throw, pitää nääs aina ratkoa promiset sanoo.
      // Näitä vastaavia oli muissakin actionsseissa.
      // T 15.8.2019
      //throw(error);//console.log("ERROR: ", error);
      return dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_ERROR, error});
      
    });

  };
}

// Resetoi Formi
export function resetMaintenanceRequestForm () {
  return function (dispatch) {
    dispatch({type: actionTypes.MAINTENANCE_REQUEST_FORM_RESET});
  }
}
