import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preloader from './UI/Preloader/Preloader';
import { isAuthenticated } from '../actions/authActions';


class UserLoadedWrapper extends Component {
  

  render() {



    let renderContent = this.props.children;

    // HAh, ei näytetä sisältöä silloin kun ladataan käyttäjää (eli taloyhtiötä)
    // Tässä kuitenkin tulee ongelmia, jos eijo yhtää taloyhtiöö itellä, ei voi kirjautua ulos!
    // Ellei tsekata tässä.. 
    // JA varmast väärä paikka tsekata
    // Teemu 5.9.2019
    // console.log(this.props.location);
    if (!this.props.isUserLoaded && this.props.location.pathname !== "/auth/logout/")
      renderContent = <Preloader />

    return (
      <div>
        {renderContent}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUserLoaded : state.myinfo.userLoaded,
  }
};

export default connect(mapStateToProps)(UserLoadedWrapper);
