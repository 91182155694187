/**
 *  Dokumenttien listauskomponentti.
 *  Hakee dokumentit ja näyttää ne.
 * Vastaava kuin ArticleList
 */

import React from 'react';
import Document from './Document';

const Documentlist = (props) => {

    const disableDownloads = props.disableDownloads === true;
    const {items, onClick, hide} = props;

    if (hide === true)
      return '';
    

  return (

        <section className="Documentlist">
            <div className="row">
              { items.length > 0 ?

                items.map((item, index)=>{
                  
                  return (
                    <div className="col-12" key={index}>
                      <Document
                        id={item.id}
                        date = {item.created_at}
                        title = {item.title}
                        message={item.message}
                        attachments={item.attachments}
                        status={item.status}
                        disableDownloads={disableDownloads}
                        onClick={onClick}
                      />
                    </div>
                  )
                })

                : <div className="col-12">Ei dokumentteja.</div>
              }
            </div>
        </section>


  )

}

export default Documentlist;
