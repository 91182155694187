import axios from 'axios';
import { APIpath } from '../shared/util';

class BulletinApi {

  static getArticles(currentUserID) {
    return axios.get(`${APIpath}users/${currentUserID}/bulletins`).then(response => {
    //return axios.get(`${APIpath}users/123/bulletins`).then(response => {
      return response;
    }).catch(error => {
      throw(error);
    });
  }
}
export default BulletinApi;
