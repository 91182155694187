/**
* Bulletin sagas
*/
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { actions } from '../actions/bulletinActions'
import * as actionTypes from '../actions/actionTypes'
import bulletinApi from  '../api/bulletinApi'
import { considerAsOldContent, getSeen } from '../shared/util'


const fetchArticles = (id) => bulletinApi.getArticles(id);
const getCurrentUser = (state) => state.myinfo.currentUser.id ? state.myinfo.currentUser : false;
// ...


function* getBulletin() {
  
  try {
    
    // Select hakee statesta 
    const currentUser = yield select(getCurrentUser);
    if (currentUser) {
      const response = yield call(fetchArticles, currentUser.id)
      // TODO pitäis varmaan tuikkaa ens localstoragesta, jos on siellä. 
      // JA täs myö varmaan haluttasiin asettaa artikkelien status
      let seen = getSeen('bulletin');
      let articles = response.data.data.map( (article) => {
        try {
          if (considerAsOldContent(article.created_at.date) || seen.find(seen_id => seen_id===article.id))
            article.status = 'seen';
          else
            article.status = 'new';
        } catch(e) {
          // Nuttin
        }
        return article;
      });

      yield put(actions.success(articles))
      
    } else {
      throw String("No current user set!");
    }

  } catch (e) {
    console.log("getBulletin error", e);
    yield put(actions.error(e))
  }
}

function* refreshBulletin( action ) {
  //console.log("Refresh bulletin saga", action);
  yield put( { type : actionTypes.LOAD_BULLETIN_ASYNC.PENDING } );
}


export default function* () {
  yield all([
    takeLatest(actionTypes.LOAD_BULLETIN_ASYNC.PENDING, getBulletin),
    takeLatest(actionTypes.CURRENT_USER_CHANGED, refreshBulletin),
    //takeLatest(actionTypes.SET_CURRENT_USER.SUCCESS, refreshBulletin),
    takeLatest(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.SUCCESS, refreshBulletin),
    takeLatest(actionTypes.APP_CONNECTION_ONLINE, refreshBulletin),
  ])
}