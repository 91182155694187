import {combineReducers} from 'redux';
import app from './appReducer';
import auth from './authReducer';
import myinfo from './myInfoReducer';
import bulletin from './bulletinReducer';
import news from './newsReducer';
import documents from './documentsReducer';
import shareholder_archive from './shareholderArchiveReducer';
import board_archive from './boardArchiveReducer';
import maestrong_iframe from './maestrongIframeReducer';
import maintenance_requests from './maintenanceRequestReducer';
import maintenance_request_form from './maintenanceRequestFormReducer';
import contact_form from './contactFormReducer';
import add_user_form from './addUserFormReducer';
import modal from './modalReducer';
import { connectRouter } from 'connected-react-router'

export default (history) => combineReducers({
  app,
  auth,
  myinfo,
  bulletin,
  shareholder_archive,
  board_archive,
  maestrong_iframe,
  news,
  documents,
  maintenance_requests,
  maintenance_request_form,
  contact_form,
  add_user_form,
  modal,
  router: connectRouter(history)
});
