import * as actionTypes from '../actions/actionTypes.js'
import { createReducer } from '../store/Utilities'

const initialState = {
  articles : [],
  isLoading: false,
  error: null
}

export default createReducer(initialState, {
  [actionTypes.LOGOUT_BOARD_ARCHIVE](state) {
    return {
      ...state,
      articles: [],
      error : false
    }
  },  
  [actionTypes.LOAD_BOARD_ARCHIVE_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true,
      error : false
    }
  },
  [actionTypes.LOAD_BOARD_ARCHIVE_ASYNC.SUCCESS](state, action) {
//    console.log(action);
    return {
      ...state,
      articles: action.articles,
      isLoading: false,
      error : false
    }
  },
  [actionTypes.LOAD_BOARD_ARCHIVE_ASYNC.ERROR](state) {
    return {
      ...state,
      articles: [],
      isLoading: false,
      error : true
    }
  }

})
