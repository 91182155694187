import * as actionTypes from '../actions/actionTypes.js'
import { createReducer } from '../store/Utilities'

const initialState = {
  articles : [],
  isLoading: false,
  error: null
}

export default createReducer(initialState, {
  [actionTypes.LOGOUT_NEWS](state) {
    return {
      ...state,
      articles: [],
      isLoading: false,
      error : false
    }
  },  
  [actionTypes.LOAD_NEWS_ASYNC.PENDING](state) {
    
    return {
      ...state,
      isLoading: true,
      error : false
    }
  },
  [actionTypes.LOAD_NEWS_ASYNC.SUCCESS](state, action) {
    console.log("load_news_async--success reducer", action);
    return {
      ...state,
      articles: action.articles,
      isLoading: false,
      error : false
    }
  },
  [actionTypes.LOAD_NEWS_ASYNC.ERROR](state) {
    return {
      ...state,
      // articles: [],
      isLoading: false,
      error : true
    }
  }
})
