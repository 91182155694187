import * as actionTypes from '../actions/actionTypes.js'
import { toast } from 'react-toastify';

const initialState = {
    online : navigator.onLine ? true : false,
    menuOpen : false,
}

const appReducer = (state = initialState, action) => {

    switch(action.type) {
      case actionTypes.TOGGLE_MENU : 
        return {
          ...state,
          menuOpen : action.menuOpen     
        }
      case actionTypes.CLOSE_MENU : 
        return {
          ...state,
          menuOpen : false     
        }
      case actionTypes.OPEN_MENU : 
        return {
          ...state,
          menuOpen : true     
        }

      case actionTypes.APP_ARTICLE_CLICKED : 
        return {
          ...state
        }


      case actionTypes.APP_CONNECTION_ONLINE :
        
        if (state.online === false) {
            toast.success('Verkkoyhteys muodostettu', {
              pauseOnFocusLoss: false
            });
        }
        return {
          ...state,
          online : true     
        }
  
      case actionTypes.APP_CONNECTION_OFFLINE :
        if (state.online === true) {
            toast.warn('Ei verkkoyhteyttä...', {
              pauseOnFocusLoss: false
            });
        }
        return {
          ...state,
          online : false     
        }        
  
      case actionTypes.APP_RATE_LIMIT_EXCEEDED :
        toast.error("Liian monta pyyntöä. Odota hetki ja kokeile uudelleen.");
        return {
          ...state
        }

      default:
        return state;
    }
  
  };
  
  export default appReducer;