import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import FolderedDocumentList from '../FolderedDocumentList';
import { connect } from 'react-redux'
import { articleClick } from '../../actions/appActions'
import Preloader from '../UI/Preloader/Preloader';

const RoutedFolderedDocumentList = withRouter( (props) => { return <FolderedDocumentList {...props}/> } );

class Page_Board_Archive extends Component {

  loader() {
    if (this.props.isLoading) {
      // rendataan syrjään, jos on jo jotain artikkeleita mitä näyttää
      return <Preloader absolute={ this.props.articles }/>
    }
  }

  // Palastellaan artikkelit kansioihin, jos on 
  folders() {
    
    let folders = [];

    if (!this.props.articles.length || typeof this.props.articles[0].folders == 'undefined')
      return this.props.articles;


    for (let i in this.props.articles) {
        let article = this.props.articles[i];
        for (let k in article.folders) {
          let af = article.folders[k];

          if (typeof folders[af.id] === 'undefined') 
            folders[af.id] = { folder : af, articles : [] };

          folders[af.id].articles.push(article);
        }
    }

    // Javacsript arrayt numeroavaimet omituisii, esim arr[3] = true, [null,null,null,true]
    // Otetaan tyhjät veke, eli resetoidaan avaimet
    return folders.filter( (item) => item !== undefined );
     
  }

  renderContent() {

    if (this.props.error) {
      return <div>Dokumentteja hakiessa tapahtui virhe.</div>
    }

    else {
      return (
          <div>
          <h1 className="page-title">Hallituksen tietopankki</h1>
          { this.loader() }
          { this.props.isLoading ? '' : <RoutedFolderedDocumentList items={this.folders()} disableDownloads={this.props.disableDownloads} onClick={this.props.onArticleClick}/> }
          </div>
      )
    }
  }

  render() {
    
    
    return <div>{this.renderContent() }</div>
  }
/*
  componentDidMount() {
    this.props.dispatch(loadBulletin(this.props.userID));
  }
*/
}

const mapStateToProps = (state) => {
  return {
    articles : state.board_archive.articles,
    isLoading: state.board_archive.isLoading,
    error: state.board_archive.error,
    userID : state.myinfo.currentUser ? state.myinfo.currentUser.id : false,
    disableDownloads : state.app.online ? false : true,
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    onArticleClick: (article) => {
      dispatch(articleClick('board_document', article));
    }    
  }  
};
export default connect(mapStateToProps, mapDispatchToProps)(Page_Board_Archive);
