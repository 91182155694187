import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
  articles : [],
  isLoading: false,
  error: null
}

const maintenanceRequestReducer = (state = initialState, action) => {

  switch(action.type) {
    case actionTypes.LOGOUT_MAINTENANCE_REQUESTS :
      return {
        ...state,
        isLoading: false,
        error: false,
        articles : []
      }
    case actionTypes.LOAD_MAINTENANCE_REQUESTS_FETCH :
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case actionTypes.LOAD_MAINTENANCE_REQUESTS_SUCCESS :
      return {
        ...state,
        articles: action.payload.data.data,
        isLoading: false,
        error: false
      }
      case actionTypes.LOAD_MAINTENANCE_REQUESTS_ERROR:
        return {
          ...state,
          isLoading: false,
          error: action.error
        }
    default:
      return state;
  }

};

export default maintenanceRequestReducer;
