import React, { Component } from 'react';
import {connect} from 'react-redux';
import Input from '../UI/Input/Input';
import DOMNotification from '../UI/Notification/DOMNotification';
import Preloader from '../UI/Preloader/Preloader';
import ProgressBar from '../UI/Progressbar/Progressbar';
//import {handleInputChange} from '../../shared/util';
//import uploadIMG from '../../images/icon-add-photo.svg';
import Articlelist from '../Articlelist';
import FormAttachment from '../Form_attachment';
import {loadMaintenanceRequests} from '../../actions/maintenanceRequestActions';
import {sendMaintenanceRequestForm,
        uploadMaintenanceFormAttachment,
        setMaintenanceRequestFormInitialValues,
        resetMaintenanceRequestForm,
        inputChanged} from '../../actions/maintenanceRequestFormActions';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {appendTokenToUrl, Messages} from '../../shared/util';

class Page_Maintenance_Request extends Component {

  // FORMIN LÄHETYS
  formSubmitHandler = (event) => {
    event.preventDefault();

    const formData = {};

    for (let formElementIdentifier in this.props.form) {
      formData[formElementIdentifier] = this.props.form[formElementIdentifier].elementconfig.value;
    }

    // Attachment-ID:t messiin
    formData.attachment_ids = [];

    for (let i=0; i<this.props.form_attachments.length; i++) {
      formData.attachment_ids.push(this.props.form_attachments[i].id);
    }

    this.props.dispatch(sendMaintenanceRequestForm(formData));

  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.props.dispatch(inputChanged(event.target.value, inputIdentifier));
  }

  // Kuvien upload
  fileSelectedHandler = event => {
    this.props.dispatch(uploadMaintenanceFormAttachment(event.target.files));
  }

  render() {

    const formElementsArray = [];

    for (let key in this.props.form) {
      formElementsArray.push({
        id: key,
        config: this.props.form[key],
      })
    }

    let form = (
      <form onSubmit={this.formSubmitHandler}>

        {formElementsArray.map(formElement => (

        <Input
            key={formElement.id}
            elementtype={formElement.config.elementtype}
            elementconfig={formElement.config.elementconfig}
            value={formElement.config.elementconfig.value}
            label = {formElement.config.label}
            changed = {(event) => this.inputChangedHandler(event, formElement.id)}
            invalid = {!formElement.config.valid}
            disabled = {this.props.offline}
            shouldValidate = { formElement.config.validation }
            touched = { formElement.config.touched } />
        ))}

        <label>Kuva</label>

        <input
          style={{display: 'none'}}
          type="file"
          name="attachments[]"
          onChange={this.fileSelectedHandler}
          ref={fileInput => this.fileInput = fileInput }
          disabled={this.props.offline}
          multiple />

          {
            this.props.form_attachments.length > 0 &&

            <div className="files-container">
            {
                this.props.form_attachments.map((object, i) => {
                  return <FormAttachment
                            key={object.id} attachment={object}
                            thumbnail = {appendTokenToUrl(object.thumbnail)}
                            handleClick = {() => {

                                //this.props.dispatch(confirmDeleteAttachment(object));
                                this.props.dispatch({
                                  type: 'SHOW_MODAL',
                                  modalType: 'DELETE_ATTACHMENT',
                                  modalProps: {
                                    object: object
                                  }
                                })
                            }}
                            />}
                )
            }
            </div>
          }
          { this.props.is_attachments_loading &&
            <div>
              <span className="text">Ladataan liitteitä, hetki...</span>
              <ProgressBar progress={this.props.attachment_load_progress} />
            </div>
          }

        <button type="button" className="btn btn-upload" onClick={() => this.fileInput.click()} disabled={this.props.offline}>
          <svg fill="#f7931d" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
            <path fill="none" d="M0 0h24v24H0z"/><path d="M21 6h-3.17L16 4h-6v2h5.12l1.83 2H21v12H5v-9H3v9c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM8 14c0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5-5 2.24-5 5zm5-3c1.65 0 3 1.35 3 3s-1.35 3-3 3-3-1.35-3-3 1.35-3 3-3zM5 6h3V4H5V1H3v3H0v2h3v3h2z"/>
          </svg>
          <div className="text">Lisää kuva</div>
        </button>

        <div className="form-element">
        <input type="submit" className="btn btn-primary"  value="Lähetä" disabled={!this.props.formIsValid || this.props.offline} />
        </div>

      </form>

    );

    let articlelist = <Articlelist articles={this.props.articles} disableDownloads={this.props.disableDownloads} />;

    if (this.props.form_is_sending) {
      form = <Preloader/>
    }

    if (this.props.sentSuccess) {
      form = <DOMNotification
              type="success"
              title={Messages.maintenanceform.sent.title}
              message={Messages.maintenanceform.sent.message}
              clickEvent={()=>{
                this.props.dispatch(resetMaintenanceRequestForm());

                // Alkuarvot lomakkeelle: email, nimi, puhelin
                this.props.dispatch(setMaintenanceRequestFormInitialValues({
                  email : this.props.email,
                  firstname : this.props.firstname,
                  lastname : this.props.lastname,
                  phone : this.props.phone
                }));

              }}/>
    }

    if (this.props.requests_loading) {
      articlelist = <Preloader/>
    }

    return  (
    <div>
        <h1 className="page-title">Huoltopyyntö</h1>

        <Tabs>
          <TabList>
            <Tab>Tee huoltopyyntö</Tab>
            <Tab>Huoltopyynnöt</Tab>

          </TabList>
          <TabPanel>
            {form}
          </TabPanel>
          <TabPanel>
            {this.props.isLoading ? '' : articlelist}
          </TabPanel>
        </Tabs>

    </div>
    )

  }

  componentDidMount () {

    // Alkuarvot lomakkeelle: email, nimi, puhelin
    this.props.dispatch(setMaintenanceRequestFormInitialValues({
      email : this.props.email,
      firstname : this.props.firstname,
      lastname : this.props.lastname,
      phone : this.props.phone,
    }));

    // Ladataan huoltopyyntölista
    this.props.dispatch(loadMaintenanceRequests(this.props.userID));

  }
}

const MapStateToProps = (state) => {

  return {
    form : state.maintenance_request_form.form,
    userID : state.myinfo.currentUser.id,
    email : state.myinfo.myinfo.email,
    firstname : state.myinfo.myinfo.firstname,
    lastname : state.myinfo.myinfo.lastname,
    phone : state.myinfo.myinfo.phone,
    articles : state.maintenance_requests.articles,
    requests_loading : state.maintenance_requests.isLoading,
    formIsValid : state.maintenance_request_form.formIsValid,
    form_attachments : state.maintenance_request_form.attachments,
    is_attachments_loading: state.maintenance_request_form.isAttachmentsUploading,
    attachment_load_progress: state.maintenance_request_form.attachment_uploadProgress,
    form_is_sending : state.maintenance_request_form.isSending,
    sentSuccess : state.maintenance_request_form.formSentSuccess,
    disableDownloads : state.app.online ? false : true,
    offline : state.app.online ? false : true,
  }
}

export default connect (MapStateToProps)(Page_Maintenance_Request);
