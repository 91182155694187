import axios from 'axios';
import {APIpath, getUserID } from '../shared/util';

class MaintenanceRequestApi {

  static fetchMaintenanceRequests() {

    return axios.get(`${APIpath}users/${getUserID()}/maintenance-requests`).then(response => {
      return response;
    }).catch(error => {
      throw(error);
    });
  }

  static sendMaintenanceRequestForm(formData) {

    return axios.post(`${APIpath}users/${getUserID()}/maintenance-requests`,
      formData
    ).then(response => response)
    .catch(error => {
      throw(error);
    });
  }

}
export default MaintenanceRequestApi;
