/**
* News sagas
*/
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { actions } from '../actions/newsActions'
import * as actionTypes from '../actions/actionTypes'
import newsApi from  '../api/newsApi'

const fetchArticles = (id) => newsApi.getArticles(id);
const getCurrentUser = (state) => state.myinfo.currentUser.id ? state.myinfo.currentUser : false;
// ...


function* getNews() {
  
  try {

    // Select hakee statesta 
    const currentUser = yield select(getCurrentUser);
    if (currentUser) {
      const articles = yield call(fetchArticles, currentUser.id)
      yield put(actions.success(articles.data.data))
    } else {
      throw String("No current user set!");
    }

  } catch (e) {
    console.log(e)
    yield put(actions.error(e))
  }
}

function* refreshNews( action ) {
  console.log("Refresh news saga", action);
  yield put( { type : actionTypes.LOAD_NEWS_ASYNC.PENDING } );
}

export default function* () {
  yield all([
    takeLatest(actionTypes.LOAD_NEWS_ASYNC.PENDING, getNews),
    // Laataan uutiset vasta kun niitä mänöö kahtomaan.
    takeLatest(actionTypes.SHOULD_REFRESH_NEWS, refreshNews),
    //takeLatest(actionTypes.SET_CURRENT_USER.SUCCESS, refreshNews),
    //takeLatest(actionTypes.APP_CONNECTION_ONLINE, refreshNews),
  ])
}