import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';

import { connect } from 'react-redux'
import {MaestrongOstolaskutURI} from '../../shared/util';


class MaestroNG_Iframe extends Component {

  loader() {
    if (this.props.isLoading) {
      // rendataan syrjään, jos on jo jotain artikkeleita mitä näyttää
      return ''; // return <Preloader absolute={ this.props.articles }/>
    }
  }

  maybeRedirect() {
    console.log("MaestrongOstolaskutURI " + MaestrongOstolaskutURI);
    if (MaestrongOstolaskutURI) {
      return ( <Route exact path="/" render={() => (window.location = MaestrongOstolaskutURI)} /> );
    }

    return '';
  }

  renderContent() {

    if (this.props.error) {
      return <div>Virhe.</div>
    }

    else {
      return (
          <div>
          <h1 className="page-title">MaestroNG ostolaskut</h1>
          { this.loader() }
          { this.props.isLoading ? '' : 'Testi' }
          { this.maybeRedirect() }
          </div>
      )
    }
  }

  render() {
    
    
    return <div>{this.renderContent() }</div>
  }
/*
  componentDidMount() {
    this.props.dispatch(loadBulletin(this.props.userID));
  }
*/
}

const mapStateToProps = (state) => {
  return {

    isLoading: state.maestrong_iframe.isLoading,
    error: state.maestrong_iframe.error,
    userID : state.myinfo.currentUser ? state.myinfo.currentUser.id : false,
    disableDownloads : state.app.online ? false : true,
  }
};

export default connect(mapStateToProps)(MaestroNG_Iframe);
