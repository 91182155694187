import React, { Component } from 'react';
import {connect} from 'react-redux';
import Input from '../UI/Input/Input';
import Preloader from '../UI/Preloader/Preloader';
//import DOMNotification from '../UI/Notification/DOMNotification';
import {sendForm,
        setFormInitialValues,
        inputChanged} from '../../actions/addUserFormActions';
//import {Messages} from '../../shared/util';

class Page_Add_User extends Component {

  // FORMIN LÄHETYS
  formSubmitHandler = (event) => {
    event.preventDefault();

    const formData = {};

    for (let formElementIdentifier in this.props.form) {
      formData[formElementIdentifier] = this.props.form[formElementIdentifier].elementconfig.value;
    }
    this.props.dispatch(sendForm(formData));
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.props.dispatch(inputChanged(event.target.value, inputIdentifier));
  }

  render () {

    const formElementsArray = [];
    for (let key in this.props.form) {
      formElementsArray.push({
        id: key,
        config: this.props.form[key],
      })
    }

    let form = (
      <form onSubmit={this.formSubmitHandler}>
        {formElementsArray.map(formElement => (

          <Input
            key={formElement.id}
            elementtype={formElement.config.elementtype}
            elementconfig={formElement.config.elementconfig}
            value={formElement.config.elementconfig.value}
            label = {formElement.config.label}
            changed = {(event) => this.inputChangedHandler(event, formElement.id)}
            invalid = {!formElement.config.valid}
            shouldValidate = { formElement.config.validation }
            disabled = {this.props.offline}
            touched = { formElement.config.touched } />
          ))}

        <input type="submit" className="btn btn-primary"  value="Lisää" disabled={ !this.props.formIsValid || this.props.offline } />

      </form>
    );

    if (this.props.form_is_sending) {
      form = <Preloader inline="true" />
    }

    /** Turha, jää herkäst päälle, ei näytetä ies. Toast näyttääe että käyttäjä lisätty
     * Teemu 4.9.2019

    if (this.props.sentSuccess) {
      form = <DOMNotification
              type="success"
              title= {this.props.referencedUserName}
              message = ""
              clickEvent={()=>{
                // PItäis olla this.resetForm joka dispathc ADD_USER_FORM_RESET, jossa addUserFormActions resetForm
                this.props.dispatch(resetForm());

                this.props.dispatch( {type: ADD_USER_FORM_SET_INITIAL_VALUES });

              }}/>
    }
    */



    return (
      <div>
      <h1 className="page-title">Lisää taloyhtiösi</h1>
        <article className="card text-center">
          {this.props.no_users ? (
            <p>Kerro oman taloyhtiösi tunniste, niin saat taloyhtiön tiedot näkyville!<br/>
            Taloyhtiön tunnisteen saat isännöitsijältä tai asiakaspalvelusta.</p>
          ) : (
            <p>Voit liittää useita taloyhtiöitä äppiisi antamalla lisää taloyhtiön tunnisteita!</p>
          )
          }
          {form}
        </article>
      </div>
    )
  }

  componentDidMount () {

    // Alkuarvot lomakkeelle:
    this.props.dispatch(setFormInitialValues({
      hash_id : this.props.hash_id,
    }));
  }

};


const MapStateToProps = (state) => {

  return {
    form : state.add_user_form.form,
    no_users : state.myinfo.myinfo.users.length ? false : true, 
    hash_id : state.myinfo.user_reference.hash_id,
    formIsValid : state.add_user_form.formIsValid,
    form_is_sending : state.add_user_form.isSending,
    sentSuccess : state.add_user_form.formSentSuccess,
    offline : state.app.online ? false : true,
    referencedUserName : state.myinfo.user_reference.name
  }
}
export default connect (MapStateToProps)(Page_Add_User);
