import * as actionTypes from '../actions/actionTypes.js'

const initialState = {
  modalType: null,
  modalProps: {}
}

const modalReducer = (state = initialState, action) => {

  switch(action.type) {
    case actionTypes.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      }
    case actionTypes.HIDE_MODAL:
      return initialState
    default:
      return state
  }

};

export default modalReducer;
