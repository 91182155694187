import React, { Component } from 'react';
import Articlelist from '../Articlelist';
import { connect } from 'react-redux'
 import {SHOULD_REFRESH_NEWS} from '../../actions/actionTypes';
import Preloader from '../UI/Preloader/Preloader';

class Page_News extends Component {

  loader() {
    if (this.props.isLoading) {
      // rendataan syrjään, jos on jo jotain artikkeleita mitä näyttää
      return <Preloader absolute={ this.props.articles }/>
    }
  }
  renderContent() {

    if (this.props.error) {
      return <div>Uutisia hakiessa tapahtui virhe.</div>
    }

    else {
      return (
          <div>
          <h1 className="page-title">Uutiset</h1>
          { this.loader() }
          {this.props.isLoading ? '' : <Articlelist articles={this.props.articles} disableDownloads={this.props.disableDownloads}  showMeta={true}/> }
          </div>
      )
    }
  }

  render() {
    return <div>{this.renderContent() }</div>
  }

  componentDidMount() {
    this.props.dispatch( { type: SHOULD_REFRESH_NEWS } );
  }

}

const mapStateToProps = (state) => {
  return {
    articles : state.news.articles,
    isLoading: state.news.isLoading,
    error: state.news.error,
    userID : state.myinfo.currentUser ? state.myinfo.currentUser.id : false,
    disableDownloads : state.app.online ? false : true,
  }
};

export default connect(mapStateToProps)(Page_News);
