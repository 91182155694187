import React from 'react';
import { Route,
         Redirect,
         } from 'react-router-dom';
import UserLoadedWrapper from './UserLoadedWrapper';


const PrivateRoute = ({
    component: Component,
    authed,
    isUserLoaded,
    location,
    ...rest
}) => (
  <Route
    {...rest}
    render={props => authed === true
      ? (<UserLoadedWrapper isUserLoaded={isUserLoaded} location={location}>
          <Component {...props} />
        </UserLoadedWrapper>
      ) : (
        <Redirect
          to={{
            pathname: "/",// pathname: "/auth/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
