import React, { Component } from 'react';
import Header from './Header';
import ModalRoot from './modals/ModalRoot';
import { ToastContainer } from "react-toastify";

class Layout extends Component {

  mainClass() {
    let classes = []
    if (this.props.offline)
      classes.push('offline');
 

    return classes.join(' ');
  }
  render() {
    return (

      <div className="App">
        <ModalRoot/>
        <Header location={this.props.location}/>
        <ToastContainer autoClose={5000} />
        <main className={this.mainClass()}>

          {this.props.children}

        </main>
      </div>

    );
  }
}

export default Layout;
