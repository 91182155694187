/**
 *  Artikkeleiden listauskomponentti.
 *  Hakee artikkelit ja näyttää ne.
*/

import React from 'react';
import Article from './Article';

const Articlelist = (props) => {


    let disableDownloads = props.disableDownloads === true;
    let showMeta = props.showMeta === true ? true : false;
    let articles = props.articles || [];
    
  return (

        <section className="Articlelist">

        { articles.length > 0 ?

          articles.map((article, index)=>{
            
            return (

              <Article
                id={article.id}
                key={index}
                date = {article.created_at}
                link = {article.link}
                title = {article.title}
                message={article.message}
                attachments={article.attachments}
                status={article.status}
                disableDownloads={disableDownloads}
                showMeta={showMeta}
                onClick={props.onClick}
              />
            )
          })

          : <div>Ei artikkeleita.</div>
        }
        </section>


  )
  
}

export default Articlelist;
