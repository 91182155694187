/**
* Shareholder archive sagas
*/
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { actions } from '../actions/shareholderArchiveActions'
import * as actionTypes from '../actions/actionTypes'
import shareholderArchiveApi from  '../api/shareholderArchiveApi'
import { considerAsOldContent, getSeen } from '../shared/util'

const fetchArticles = (id) => shareholderArchiveApi.getArticles(id);
const getCurrentUser = (state) => state.myinfo.currentUser.id ? state.myinfo.currentUser : false;
// ...


function* getShareholderArchive() {
  
  try {
    
    // Select hakee statesta 
    const currentUser = yield select(getCurrentUser);
    if (currentUser) {

      if (!currentUser.shareholder) {
        throw String("Not shareholder.");
      } else {

        const response = yield call(fetchArticles, currentUser.id)
        // TODO pitäis varmaan tuikkaa ens localstoragesta, jos on siellä. 
        // JA täs myö varmaan haluttasiin asettaa artikkelien status
        let seen = getSeen('shareholder_document');
        let articles = response.data.data.map( (article) => {
          try {
            if (considerAsOldContent(article.created_at.date) || seen.find(seen_id => seen_id===article.id))
              article.status = 'seen';
            else
              article.status = 'new';
          } catch(e) {
            // Nuttin
          }
          return article;
        });
    
        yield put(actions.success(articles))
      }
    } else {
      throw String("No current user set!");
    }

  } catch (e) {
   // console.log("getShareholderArchive error", e);
    yield put(actions.error(e))
  }
}

function* refreshShareholderArchive( action ) {
  //console.log("Refresh bulletin saga", action);
  yield put( { type : actionTypes.LOAD_SHAREHOLDER_ARCHIVE_ASYNC.PENDING } );
}



export default function* () {
  yield all([
    takeLatest(actionTypes.LOAD_SHAREHOLDER_ARCHIVE_ASYNC.PENDING, getShareholderArchive),
    takeLatest(actionTypes.CURRENT_USER_CHANGED, refreshShareholderArchive),
    //takeLatest(actionTypes.SET_CURRENT_USER.SUCCESS, refreshShareholderArchive),
    takeLatest(actionTypes.APP_CONNECTION_ONLINE, refreshShareholderArchive),
    takeLatest(actionTypes.SHAREHOLDER_AUTH_ASYNC.SUCCESS, refreshShareholderArchive)    
  ])
}