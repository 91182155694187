import React, { Component } from 'react';
import {connect} from 'react-redux';


import {set_current_user_actions} from '../../actions/myInfoActions';

class Page_Switch_User extends Component {


  inputChangedHandler = (event) => {
    this.props.dispatch(set_current_user_actions.pending(event.target.value));
  }

  render () {

    return (
      <div>
      <h1 className="page-title">Vaihda taloyhtiö</h1>
        <article className="card">

          <h1 className="article-title">Taloyhtiöt</h1>
          <label>Valitse taloyhtiö</label>
          <select defaultValue={this.props.currentUserID} className="dg-select" onChange={this.inputChangedHandler}>
          {
            this.props.users.map((company, index)=>{
              return (
              <option key={index} value={company.id}>{company.company}</option>
              )
            })
           }
           </select>

        </article>
      </div>
    )
  }

  componentDidMount () {}

};


const MapStateToProps = (state) => {

  return {
    users : state.myinfo.myinfo.users,
    currentUserID : state.myinfo.currentUser.id
    /*
    form : state.add_user_form.form,
    hash_id : state.myinfo.user_reference_hash_id,
    formIsValid : state.add_user_form.formIsValid,
    form_is_sending : state.add_user_form.isSending,
    sentSuccess : state.add_user_form.formSentSuccess,
    offline : state.app.online ? false : true,
    referencedUserName : state.myinfo.user_reference_name
    */
  }
}
export default connect (MapStateToProps)(Page_Switch_User);
