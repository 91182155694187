import axios from 'axios';
import { APIpath } from '../shared/util';

class NewsApi {

  static getArticles(currentUserID) {

    // Uutiset tulee news.istonetti.fi:stä, mutta pietääs api backendi välissä!
    return axios.get(`${APIpath}users/${currentUserID}/news`).then(response => {

    //return axios.get(`${APIpath}users/123/news`).then(response => {
    // console.log("news RESPONSE ", response);
      
      return response;
    }).catch(error => {
      throw(error);
    });
  }
}
export default NewsApi;
