import React from 'react';

const input = (props) => {

  let inputElement = null;
  const inputClasses = [];

  if (props.invalid && props.shouldValidate.required && props.touched && props.isVisible !== false) {
    inputClasses.push('invalid');
  }
  
  let { className } = props.elementconfig;
  if (typeof className == 'undefined')
    className = 'form-control';

  className = className + ' ' + inputClasses.join( ' ' );

  // VIsible? 
  // Tarvitaan kun email kirjautumishommalla, samalla lomakkeella meinasin piilottaa
  // salasana kentän enneks lähettää email kentän.
  // TODO: pitäis varsin olla kaksvaiheinen kuten vaikka login.microsoftonline.com tai gmail on.
  // teemu 9.9.2019
  if (props.isVisible === false)
    return '';


  switch (props.elementtype) {
    case ('input') :
      inputElement = <input
        className={className}
        {...props.elementconfig}
        value={props.elementconfig.value}
        onChange={props.changed} disabled={props.disabled}/>;
      break;

    case ('textarea') :
      inputElement = <textarea
        className={className}
        {...props.elementconfig}
        value={props.elementconfig.value}
        onChange={props.changed} disabled={props.disabled}/>;
      break;

    default:
      inputElement = <input
        className={className}
        {...props.elementconfig}
        value={props.elementconfig.value}
        onChange={props.changed} disabled={props.disabled}/>;
  }

  function label(text) {
    if (text)
      return <label>{text}</label>;
    else 
      return;
  }

  return (
    <div className="form-element">
      {label(props.label)}
      {inputElement}
    </div>
  )
};

export default input;
