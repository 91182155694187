import * as actionTypes from './actionTypes';
import { createAction } from '../store/Utilities'
/* ei enää haetakkaa tääl actionissa (thunk), vaan sagassa, tsiigaa /sagas/bulletinSagas.js
import bulletinApi from '../api/bulletinApi';

export function loadBullletin(id) {

  // make async call to api, handle promise, dispatch action when promise is resolved
  return function(dispatch) {

    dispatch({type: actionTypes.LOAD_BULLETIN_FETCH});
    return bulletinApi.getArticles(id).then(payload => {

      dispatch({type: actionTypes.LOAD_BULLETIN_SUCCESS, payload});
    }).catch(error => {

      dispatch({type: actionTypes.LOAD_BULLETIN_ERROR, error});
      console.log("ERROR: ", error);
    });
  };
}


*/
// https://hackernoon.com/replacing-redux-thunks-with-redux-sagas-4aa306854925
export const actions = {
  pending: () => createAction(actionTypes.LOAD_BULLETIN_ASYNC.PENDING),
  success: (articles) => createAction(actionTypes.LOAD_BULLETIN_ASYNC.SUCCESS, { articles }),
  error: (error) => createAction(actionTypes.LOAD_BULLETIN_ASYNC.ERROR, { error })
}