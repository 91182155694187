import React, { Component, useRef, useState } from 'react';
import IframeResizer from 'iframe-resizer-react'
// @see https://github.com/davidjbradshaw/iframe-resizer-react
// Oma iframe komponentti. Iframen sisällössä tarvii olla toi iframeResizer.contentWindow.min.js skripti.
// Teemu 10.10.2019

   
    export default (props) => {
      /*
      const iframeRef = useRef(null)
      const [messageData, setMessageData] = useState(undefined)
    
      const onResized = data => setMessageData(data)
    
      const onMessage = data => {
        setMessageData(data)
        iframeRef.current.sendMessage('Hello back from the parent page')
      }
    */
      return (
        <div>
            <IframeResizer
  log
  frameBorder="0" 
  src={props.src}
  style={{ width: '1px', minWidth: '100%'}}
/>

        </div>
      )
    }
/*
    <IframeResizer
    forwardRef={iframeRef}
    heightCalculationMethod="lowestElement"
    inPageLinks
    log
    onMessage={onMessage}
    onResized={onResized}
    src="http://anotherdomain.com/iframe.html"
    style={{ width: '1px', minWidth: '100%'}}
  />
  <div>{messageData}</div>
*/