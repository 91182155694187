/**
 *  Liitetiedostojen listauskomponentti.
*/
import React from 'react';
import Attachment from './Attachment';

const Attachments = (props) => {



    if (!Array.isArray(props.attachments)) {
      return "";
    }

    let elem = props.elem ? props.elem : 'a';

    return (
      <section className="attachments">
      {
        props.attachments.map((attachment, index)=>{
          //console.log(attachment);
          return (
            <Attachment key={index} mimetype={attachment.mimetype} url={attachment.url} name={attachment.filename} elem={elem} onClick={props.onClick}/>
          )
        })
       }
      </section>
    );

}

export default Attachments;
