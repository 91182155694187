import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../UI/Input/Input';
import {register,
        inputChanged} from '../../actions/authActions';
import { Redirect, Link } from 'react-router-dom';



class RegisterView extends Component {

  // FORMIN LÄHETYS
  formSubmitHandler = (event) => {

    event.preventDefault();
    const formData = {};

    for (let formElementIdentifier in this.props.form) {
      formData[formElementIdentifier] = this.props.form[formElementIdentifier].elementconfig.value;
    }

    this.props.dispatch(register(formData));

  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.props.dispatch(inputChanged('register', event.target.value, inputIdentifier));
  }


  showHousingCompanyName = () => {

    if (this.props.housing_company_id) {
      return (
        <p>{this.props.housing_company_name}</p>
      );
    }

    return null;
  }

  render() {

    const formElementsArray = [];
    for (let key in this.props.form) {
      formElementsArray.push({
        id : key,
        config: this.props.form[key]
      })
    }

    const form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementtype={formElement.config.elementtype}
        elementconfig={formElement.config.elementconfig}
        value={formElement.config.elementconfig.value}
        label = {formElement.config.label}
        changed = {(event) => this.inputChangedHandler(event, formElement.id)}
        invalid = {!formElement.config.valid}
        shouldValidate = { formElement.config.validation }
        touched = { formElement.config.touched } />
    ));

    let renderHTML = (
      <div>
        { this.showHousingCompanyName() }
        <h1 className="page-title">Rekisteröidy</h1>
        <article className="card">
          <form onSubmit={this.formSubmitHandler} className="register">
            {form}
            <input type="submit" className="btn btn-primary" value="Rekisteröidy" disabled={ !this.props.formIsValid || this.props.offline } />
          </form>
          <br />
          <Link to="/auth/login/" className="no-decoration centered">
            Kirjaudu sisään &raquo;
          </Link>
        </article>


      </div>

    );

    if (this.props.isAuthenticated) {
        renderHTML = (
        <Redirect to="/"/>
        );
    }

    return (
      <div>{renderHTML}</div>
    )
  }

}

const mapStateToProps = (state) => {

  return {
    redirect : state.auth.redirect,
    isAuthenticated : state.auth.isAuthenticated,
    error : state.auth.error,
    form : state.auth.form_register,
    formIsValid : state.auth.formIsValid_register,
    users : state.myinfo.myinfo.users,
    // Kirjautuessa kytketään taloyhtiöön.
    housing_company_id : state.myinfo.user_reference.id,
    housing_company_name : state.myinfo.user_reference.name,
    offline : state.app.online ? false : true,

  }
};



export default connect(mapStateToProps)(RegisterView);
