import * as actionTypes from './actionTypes';
import { createAction } from '../store/Utilities';

export const my_info_exists_actions = {
  pending: () => createAction(actionTypes.MYINFO_EXISTS_ASYNC.PENDING),
  success: () => createAction(actionTypes.MYINFO_EXISTS_ASYNC.SUCCESS ),
  error: () => createAction(actionTypes.MYINFO_EXISTS_ASYNC.ERROR ),
}
export const load_my_info_actions = {
  pending: () => createAction(actionTypes.LOAD_MYINFO_ASYNC.PENDING),
  success: (myinfo) => createAction(actionTypes.LOAD_MYINFO_ASYNC.SUCCESS, { myinfo }),
  error: (error) => createAction(actionTypes.LOAD_MYINFO_ASYNC.ERROR, { error }),
}

export const get_referenced_user_actions = {
  pending: () => createAction(actionTypes.GET_USER_REFERENCE_ASYNC.PENDING),
  success: (referencedUser) => createAction(actionTypes.GET_USER_REFERENCE_ASYNC.SUCCESS, { referencedUser }),
  error: (error) => createAction(actionTypes.GET_USER_REFERENCE_ASYNC.ERROR, { error })
}
/*
// TODO: tää pitäis olla sagana, kutsu kohilleen Page_Switch_User.js 
// 4.9.2019 teemu
export function setCurrentUser(id) {
  return (dispatch) => {
// console.log("Switching current useri", id, {type: actionTypes.SET_CURRENT_USER, id})    ;

    dispatch({type: actionTypes.SET_CURRENT_USER.PENDING, id});
  };
}
*/
export const current_user_changed_actions = {
  yes: () => createAction(actionTypes.CURRENT_USER_CHANGED)
}
export const set_current_user_actions = {
  pending: (id) => createAction(actionTypes.SET_CURRENT_USER.PENDING, { id }),
  success: (user) => createAction(actionTypes.SET_CURRENT_USER.SUCCESS, { user }),
  error: (error) => createAction(actionTypes.SET_CURRENT_USER.ERROR, { error })
}
export const add_to_my_users_actions = {
  pending: () => createAction(actionTypes.ADD_TO_MY_USERS_ASYNC.PENDING),
  success: (myusers) => createAction(actionTypes.ADD_TO_MY_USERS_ASYNC.SUCCESS, { myusers }),
  error: (error) => createAction(actionTypes.ADD_TO_MY_USERS_ASYNC.ERROR, { error })
}

export const remove_from_my_users_actions = {
  pending: () => createAction(actionTypes.REMOVE_FROM_MY_USERS_ASYNC.PENDING),
  success: (myusers) => createAction(actionTypes.REMOVE_FROM_MY_USERS_ASYNC.SUCCESS, { myusers }),
  error: (error) => createAction(actionTypes.REMOVE_FROM_MY_USERS_ASYNC.ERROR, { error })
}

/*
export function getHousingReference(hash) {
  return function(dispatch) {
    dispatch({type: actionTypes.GET_USER_REFERENCE});

    return infoApi.getHousingReference(hash).then(response => {

      // Saga ohjaa eteenpäin
      dispatch({
          type: actionTypes.GET_USER_REFERENCE_SUCCESS,
          user_reference_id : response.data.id,
          user_reference_hash_id : hash,
          user_reference_name : response.data.name
      });

    }).catch(error => {
      console.log("[authActions.js] Failed to get housing reference.");
      console.log("ERROR: ", error);
      let error_message = 'Taloyhtiön tietoja ei löytynyt.';
      // Laravel backendi palauttaa jsonissa errors avaimella virheet.
      if (error.response.data.errors) {
        error_message = Object.values(error.response.data.errors).join('<br/>');
      }

      dispatch( {
        type: actionTypes.GET_USER_REFERENCE_ERROR,
        error : error_message
      });

      throw(error);
    });
  }
}
*/
/*
export function loadMyInfo() {

  return (dispatch) => {

    console.log("[loadMyInfo]Loading my info");
    dispatch({type: actionTypes.LOAD_MY_INFO});

    return myInfoApi.getMyInfo().then(payload => {

      dispatch({type: actionTypes.LOAD_MY_INFO_SUCCESS, payload});

    }).catch(error => {

      dispatch({type: actionTypes.LOAD_MY_INFO_ERROR, error});

      // TODO tää pitäis kutsuu jotenkin, että ei aiheuta virheitä.
      dispatch(logout());
      throw(error);
    });
  };

}
*/
