import {createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from '../reducers';
import thunk from 'redux-thunk';

import createSagaMiddleware from 'redux-saga';
import { initSagas } from '../sagas/index'

import { routerMiddleware } from 'connected-react-router'

export default function configureStore(history) {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  //import {loadUser} from './actions/userActions';


  // @see https://hackernoon.com/replacing-redux-thunks-with-redux-sagas-4aa306854925
  const sagaMiddleware = createSagaMiddleware();
  


  const store = createStore(
    createRootReducer(history), 
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware, 
        thunk
      )
    )
  )
  initSagas(sagaMiddleware)
  
  return store

}
