import * as actionTypes from './actionTypes';

export function appOnlineStatus(online_status) {

    if (online_status === 'online') {
        return function(dispatch) {
            dispatch({ type: actionTypes.APP_CONNECTION_ONLINE });
        }
    } else {
        return function(dispatch) {
            dispatch({ type: actionTypes.APP_CONNECTION_OFFLINE });
        }
    }
}

/**
 * ArticleList tai DocumentList olevaa artikkelia / dokumenttia klikatessa
 * ite artikkeli/dokumentti välittyy tänne.
 * Käytetään ku merkataan luetuiksi
 * Teemu 26.9.2019
 * @param string article_type
 * @param obj article 
 */
export function articleClick(article_type, article) {
// console.log("Article click", article_type, article);
    return function(dispatch) {
        dispatch({ type: actionTypes.APP_ARTICLE_CLICKED, article: article, article_type: article_type });
    }
}