import * as actionTypes from '../actions/actionTypes.js'
import { createReducer } from '../store/Utilities'

const initialState = {
  isLoading: false,
  error: null
}

export default createReducer(initialState, {
   
  [actionTypes.LOAD_MAESTRONG_IFRAME_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true,
      error : false
    }
  },
  [actionTypes.LOAD_MAESTRONG_IFRAME_ASYNC.SUCCESS](state, action) {
//    console.log(action);
    return {
      ...state,
      isLoading: false,
      error : false
    }
  },
  [actionTypes.LOAD_MAESTRONG_IFRAME_ASYNC.ERROR](state) {
    return {
      ...state,
      isLoading: false,
      error : true
    }
  }

})
