import React from 'react';

const NewItemsBadge = (props) => {

    let number = props.count || '';

    if (number === 0)
        return '';

  return (
    <span className="badge badge-light">{number}</span>
  )
};

export default NewItemsBadge;
