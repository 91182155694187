import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {connect} from 'react-redux';
import logo from '../images/kontu-stone.png';
import { TOGGLE_MENU } from '../actions/actionTypes'
import Menuitems from './Menuitems';
import { Link } from 'react-router-dom';        
import { slide as Menu } from 'react-burger-menu'

class Header extends Component {


  offlineBar = () => {
    if (this.props.offline) {
      return ( <div className="offlineBar alert alert-warning">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
      Ei verkkoyhteyttä</div> );
    }
    return null;
  }

  // TODO: 20.9.2019 - ei toimi taas jostain syystä käyttäjän vaihto.
  // Pannaa vaihtolinkki piiloon toistaiseksi, yläpalkin jumppa menossa.
  switchUser =  () => {

    
    // Näytetään kun oma käyttäjä saatavilla.
    // Ja vain silloi ntäällä yläpalkissa, kun ei olla etusivulla
    if (this.showBackButton() && this.props.isAuthenticated && this.props.hasCurrentUser) {

      return (
        <div className="company-name">
            <div>
            <Link to="/users/" className="no-decoration">
               {this.props.currentUser.company}
            </Link>
            </div>
        </div>
      );
    }
    
  }

  
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleMenuStateChange (state) {
    this.props.dispatch( {type: TOGGLE_MENU, menuOpen : state.isOpen } );
  }

  // Etusivulla tulostetaan logo
  // Muilla sivuilla tulostetaan nuoli takasi.
  showBackButton () {
      if (this.props.location.pathname !== '/')
        return true; 

      return false;
  }

  backToFrontLink() {
    if (this.showBackButton())
      return ( <span className="menuicon icon-back"></span> );
      
    return ( <img src={logo} className="main-logo" alt="ISTO" /> );
  }

  render() {



    return (

      <header className="App-header">
        { this.offlineBar() }
        <div className="masthead">
          <NavLink to="/" className="main-logo-link">{this.backToFrontLink()}</NavLink>
          { this.switchUser() }
        </div>
        <Menu 
          right 
          className='main-menu' 
          isOpen={ this.props.isMenuOpen } 
          onStateChange={(state) => this.handleMenuStateChange(state)}>
          <Menuitems className="menu-items"/>
        </Menu>
      </header>
    );
  }
}

const MapStateToProps = (state) => {
  return {
    currentUser : state.myinfo.currentUser,
    hasCurrentUser : state.myinfo.currentUser.id ? true : false,
    isAuthenticated : state.auth.isAuthenticated,
    offline : state.app.online ? false : true,
    isMenuOpen : state.app.menuOpen
  }
}

export default connect (MapStateToProps, null, null, {
  pure: false
})(Header);

// export default withRouter(connect(MapStateToProps)(Header));
// export default Header;
