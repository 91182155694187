import React, { Component } from 'react';
import Articlelist from '../Articlelist';
import { connect } from 'react-redux'
import { articleClick } from '../../actions/appActions'

import Preloader from '../UI/Preloader/Preloader';
import { getUnseen } from '../../shared/util';

class Page_Bulletin extends Component {

  loader() {
    if (this.props.isLoading) {
      // rendataan syrjään, jos on jo jotain artikkeleita mitä näyttää
      return <Preloader absolute={ this.props.articles }/>
    }
  }
  renderContent() {

    if (this.props.error) {
      return <div>Tiedotteita hakiessa tapahtui virhe.</div>
    }

    else {

      return (
          <div>
          <h1 className="page-title">Tiedotteet</h1>
          { this.loader() }
          {this.props.isLoading ? '' : <Articlelist articles={this.props.articles} disableDownloads={this.props.disableDownloads} onClick={this.props.onArticleClick} /> }
          </div>
      )
    }
  }

  render() {
    return <div>{this.renderContent() }</div>
  }
/*
  componentDidMount() {
    this.props.dispatch(loadBulletin(this.props.userID));
  }
*/
}

const mapStateToProps = (state) => {
  return {
    articles : state.bulletin.articles,
    isLoading: state.bulletin.isLoading,
    error: state.bulletin.error,
    userID : state.myinfo.currentUser ? state.myinfo.currentUser.id : false,
    disableDownloads : state.app.online ? false : true,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onArticleClick: (article) => {
      dispatch(articleClick('bulletin', article));
    }    
  }  
};
export default connect(mapStateToProps, mapDispatchToProps)(Page_Bulletin);
