import {checkValidity} from './validation';
import axios from 'axios';
import { UNAUTHORIZED_REQUEST, APP_RATE_LIMIT_EXCEEDED } from '../actions/actionTypes';
import { appOnlineStatus } from '../actions/appActions';
import DOMPurify from 'dompurify'

/**
 *  Lisää Authorization tokenin kaikkiin axios-requesteihin.
 *  Jos ei annettu, poistaa tokenin
 */
  export const setAuthorizationToken = (token) => {
    // console.log("setting token", token);
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return;
    }
    delete axios.defaults.headers.common['Authorization'];
 }

/**
 * Napataan 401 ja muut errorit kaikista API kutsuista. 
 * 
 * @param {*} store 
 */
export const setupAPIInterceptors = (store) => {

  // Add a request interceptor
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (!config.timeout) 
      config.timeout = 1000 * 10; // 10 sekkaa timeout, jos ei eriksee asetettu
    return config;

  }, function (error) {
    // Do something with request error
    // Meillä on api/xxApi luokissa catch => (error) { return Promise.reject(error) },
      // joten tässä palautetaa vaa errori
      // EDIT: TUota, swittu ei toimi, axios menee muute then polkuun. Pakko rejectoida tässä.
       return Promise.reject(error);
      // return error;
    
  });

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
      return response;
  }, function (error) {
/*
      // Koitetaan uusia token ja lähettää uudelleen.
      if (error.config && error.response && error.response.status === 401) {
        //response.data.access_token
        console.log(error.config.headers);
        return refreshAccessToken(error.config.headers).then((token) => {
          
         // error.config.headers.xxxx <= set the token
          return axios.request(error.config);
        });
      }
    
      return Promise.reject(error);
*/
      console.log("setupAPIInterceptors: error ", error);
      
      //catches if the session ended!
      if (typeof error !== 'undefined' && typeof error.response !== 'undefined') {
        if ( 401 === error.response.status ) {
            //console.log("EXPIRED TOKEN?");
            // 25.9.2019 - ei suinkaan voida olettaa että jotain meni kuseen tokenin kanssa jos 401 tulee!
            //store.dispatch({ type: AUTH_FAILED });
            store.dispatch({ type: UNAUTHORIZED_REQUEST, response: error.response });

        // Throttletus apissa päällä
        } else if (429 == error.response.status) {
            console.log("RATE LIMIT EXCEEDED");
            store.dispatch({ type: APP_RATE_LIMIT_EXCEEDED, response: error.response });
        }
      }

      // Meillä on api/xxApi luokissa catch => (error) { return Promise.reject(error) },
      // joten tässä palautetaa vaa errori
      // return Promise.reject(error);
      // EDIT: TUota, swittu ei toimi, axios menee muute then polkuun. Pakko rejectoida tässä.
      return Promise.reject(error);
      //return error;
  });

}


 // Onko Token vanhentunut
 export const hasTokenExpired = (expiretime) => {
   console.log ("[Middleware] hasTokenExpired", expiretime);

   if (null === expiretime || "undefined" === typeof expiretime || expiretime > Date.now())
    return true;

  return false;
 }

 // Onko muuttuja tyhjä tai
 export const isEmpty = (variable) => {
  if("undefined" === typeof variable || null === variable || "" === variable)
    return true;

  return false;
 }

 // API - polku
// export const APIpath = 'https://api.pwa.hoidossa.com/api/v1/';
// Löytyy .env tiedostosta!
// Käytä .env.local tiedostossa localhostissa, eli omassa kehitysympäristössä ;)
// @see https://medium.com/@tacomanator/environments-with-create-react-app-7b645312c09d
export const APIpath = process.env.REACT_APP_API_PATH;
export const APIhost = process.env.REACT_APP_API_HOST;
export const newsAPIpath = process.env.REACT_APP_ISTO_NEWS_API_PATH;
export const istonettiOauthLoginURI = process.env.REACT_APP_ISTONETTI_OAUTH_LOGIN_URI;
export const istonettiOauthLogoutURI = process.env.REACT_APP_ISTONETTI_OAUTH_LOGOUT_URI;
export const asumisentietopankkiWidgetURI = process.env.REACT_APP_AT_WIDGET_URI;
export const MaestrongOstolaskutURI = process.env.REACT_APP_MAESTRONG_OSTOLASKUT_URI;


// Palauttaa localstorageen tallennetun user ID:n
// TODO: tää sisältöjen hakuun, mut mieluummin kokonaa tää pois
// ja käytä staten currentUserID? statesta pitää tallentaa toki
// localstorageen? - Teemu 2.11.2018
export const getUserID = () => {
  return localStorage.getItem("userID");
}

// Lisää URL:iin access tokenin
export const appendTokenToUrl = (url) => {
  return url + '?token=' + localStorage.getItem("jwtToken");
}

export const niceDate = (MySQLDate) => {
  var jsDate = new Date(Date.parse(MySQLDate.replace(/[-]/g,'/')));

  let options = { hour: 'numeric', minute: 'numeric' }
  return jsDate.toLocaleDateString('fi-FI', options);
}

/**
 * Testaa käpistelläänkö sisältöjä uutena. 
 * Jos on yli kuukauden vanhaa tietoa, turha käsitellä uutena.
 * 
 * Teemu 25.9.2019
 */
export const considerAsOldContent = (MySQLDate) => {

  if (!MySQLDate || typeof MySQLDate === 'undefined')
    return true;

  const HOUR = 1000 * 60 * 60;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;

  const jsDate = new Date(Date.parse(MySQLDate.replace(/[-]/g,'/')));
  const old = Date.now() - MONTH;

  return jsDate < old;
}

// Alustetaan form valmiilla kontaktidatalla (nimi, sähköpostiosoite, puhelinnumero)
// TODO huoneiston voisi alustaa myöskin.
export const addFormInitialContactValues = (state, action) => {

  // jos serveriltä tulevassa myinfo-objektissa tulee etunimi + sukunimi, oletetaan että se on validi
  const namevalid = action.payload.firstname.trim() !== "" || action.payload.lastname.trim() !== "" ? true : false
  // jos serveriltä tulevassa myinfo-objektissa tulee email, oletetaan että se on validi.
  const emailvalid = action.payload.email.trim() !== "" ? true : false;
  // jos serveriltä tulevassa myinfo-objektissa tulee phone, oletetaan että se on validi
  const phonevalid = action.payload.phone.trim() !== "" ? true : false;


  return {
    ...state,
    form: {
      ...state.form,
      contact_name: {
          ...state.form.contact_name,
          elementconfig : {
              ...state.form.contact_name.elementconfig,
              value : action.payload.firstname + ' ' + action.payload.lastname
          },
          valid: namevalid
      },
      contact_email: {
          ...state.form.contact_email,
          elementconfig : {
              ...state.form.contact_email.elementconfig,
              value : action.payload.email
          },
          valid: emailvalid
      },
      contact_phone: {
          ...state.form.contact_phone,
          elementconfig : {
              ...state.form.contact_phone.elementconfig,
              value : action.payload.phone
          },
          valid: phonevalid
      },
    }
  }
}


export const formInputChanged = (form, action) => {

  const updatedForm = {
    ...form
  };

  const inputIdentifier = action.payload.identifier;

  const updatedFormElement = {
    ...updatedForm[action.payload.identifier]
  };

  updatedFormElement.elementconfig.value = action.payload.value;
  updatedFormElement.valid = checkValidity(updatedFormElement.elementconfig.value, updatedFormElement.validation);
  updatedFormElement.touched = true;

  updatedForm[inputIdentifier] = updatedFormElement;

  let formIsValid = true;

  for (let inputIdentifier in updatedForm) {
    formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
  }

  return {
    form : updatedForm,
    formIsValid : formIsValid
  }
}

export const resetForm = (form) => {
  const newForm = {...form};

  for (let obj in newForm) {
    newForm[obj] = {
        ...newForm[obj],
        elementconfig : {
            ...newForm[obj].elementconfig,
            value : ''
        },
        touched : false,
        valid : false
    }
  }
  return newForm;
}

export const Messages = {
  maintenanceform : {
    sent : {
      title : 'Kiitos, huoltopyyntö tallennettiin.',
      message : ''
    }
  },
  form : {
    sent : {
      title : 'Kiitos viestistäsi!',
      message : ''
    }
  }
}

/**
 * Set state site.online on offline / online detection
 */
export const connectionMonitor = (store) => {
  window.addEventListener('load', function() {
    function updateOnlineStatus(event) {
      if (navigator.onLine) {
        // handle online status
        console.log('online');
        store.dispatch(appOnlineStatus('online'));
      } else {
        // handle offline status
        console.log('offline');
        store.dispatch(appOnlineStatus('offline'));
      }
    }

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
  });
}; 




// Oma sanitointikikkare XSS haavoittuvuukisen varalle
// Tulostetaan kuitenkin WP:stä saatua html koodia, jotka
// pitää sanitoida kaiken varuiksi.
// Käytetään vaiks dompurifyä
// @see https://github.com/OWASP/CheatSheetSeries/blob/master/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.md
// @see https://github.com/cure53/DOMPurify
/**
 * Palauttaa parametrin ajettuna dompurifierin läpi.
 * @param {string} html 
 * @return {string} html
 */
export const sanitizer = (html) => {
    return DOMPurify.sanitize(html);
}


/**
 * Pietääks localstoragessa yllä mitä sisältöjä on käyty lueskelemassa
 * 
 * @param {} type 
 * @param {*} id 
 */
export const setSeen = (type, id) => {

  let seen_content = localStorage.getItem('seen_content') || {};
  try {
    seen_content = JSON.parse(seen_content);
  } catch (e) {
    seen_content = {};
  }

  let our_type = seen_content[type] || [];
  our_type.push(id);
  // Uniikit ja sorttaus, uusin eka niitä kuiteki tarkistellaan.
  seen_content[type] = [...new Set(our_type)].sort().reverse() ;

  return localStorage.setItem("seen_content", JSON.stringify(seen_content));
}

/**
 * Pietääks localstoragessa yllä mitä sisältöjä on käyty lueskelemassa
 * 
 * @param {} type 
 * @param {*} id 
 */
export const isSeen = (type, id) => {
  let seen = getSeen(type);
  return seen.find(k => k===id);
}
/**
 * Pietääks localstoragessa yllä mitä sisältöjä on käyty lueskelemassa
 * 
 * @param {} type 
 * @param {*} id 
 */
export const getSeen = (type) => {

  let seen_content = localStorage.getItem('seen_content');
  if (!seen_content)
    return [];

  try {
    seen_content = JSON.parse(seen_content);
  } catch (e) {
    seen_content = {};
  }
  
  return seen_content[type] || [];
}

/**
 * Vertailee artikkelilistalta, mitkä on nähty ja mitkä ei.
 * Palauttaa näkemättömien lukumäärän.
 * 
 * @param {*} type 
 * @param {*} articles 
 * 
 * @return {Integer} count
 */
export const getUnseenCount = (type, articles) => {
  return getUnseen(type, articles).length;
}

/**
 * Palauttaa artikkelien ID:t, mitä eijo nähty.
 * 
 * @param {*} type 
 * @param {*} articles 
 * 
 * @return {array} unseen articles
 */
export const getUnseen = (type, articles) => {
  let unseen = [];
  try {
    let seen = getSeen(type);
    if (articles.length) {
      articles.forEach((article)=> { 
        
          if (considerAsOldContent(article.created_at.date))
            return;
          if (seen.find(k => k===article.id))
            return;

          unseen.push(article.id);
      });
    }
  } catch (e) {
    console.log("getSeen errorii ", type, e);
    return unseen;
  }

  return unseen;
}