import * as actionTypes from '../actions/actionTypes.js'
import {addFormInitialContactValues,
    formInputChanged,
    resetForm } from '../shared/util'

const initialState = {

  isSending: false,
  formSentSuccess: false,
  error: null,
  formIsValid : false,
  form : {
    message : {
      elementtype : 'textarea',
      elementconfig : {
        type : 'textarea',
        placeholder : 'Kirjoita viesti tähän',
        value : '',
      },
      label : 'Viesti *',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    },
    contact_name : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : '',
        value : '',
      },
      label : 'Nimi *',
      validation : {
        required : false
      },
      valid : false,
      touched : false,
    },
    contact_email : {
      elementtype : 'email',
      elementconfig : {
        type : 'email',
        placeholder : '',
        value : '',
      },
      label : 'Sähköpostiosoite *',
      validation : {
        required : false,
        isEmail : true
      },
      valid : false,
      touched : false,
    },
    contact_phone : {
      elementtype : 'tel',
      elementconfig : {
        type : 'tel',
        placeholder : '',
        value : ''
      },
      label : 'Puhelin *',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    }
  },


}

const contactFormReducer = (state = initialState, action) => {

  switch(action.type) {

    case actionTypes.CONTACT_FORM_SET_INITIAL_VALUES :
      return addFormInitialContactValues(state, action);

    case actionTypes.CONTACT_FORM_INPUT_CHANGED :
      const { form, formIsValid } = formInputChanged(state.form, action);
      return {
        ...state,
        form : form,
        formIsValid : formIsValid
      }

    case actionTypes.CONTACT_FORM_SEND:
      return {
        ...state,
        isSending : true,
        sentError: false,
        formSentSuccess: false
      }
    case actionTypes.CONTACT_FORM_SENT_SUCCESS:
      return {
        ...state,
        isSending : false,
        sentError : false,
        formSentSuccess: true,
      }
    case actionTypes.CONTACT_FORM_SENT_ERROR:
      return {
        ...state,
        isSending : false,
        sentError : action.error
      }
    case actionTypes.CONTACT_FORM_RESET :
      return {
        ...state,
        isSending: false,
        sentError : false,
        formSentSuccess: false,
        error: false,
        formIsValid : false,
        form: resetForm(state.form)
      }

    default:
      return state;
  }

};

export default contactFormReducer;
