// KAnsio, esim hallituksen arkistossa
import React from 'react';
import NewItemsBadge from './UI/NewItemsBadge';

const Folder = (props) => {
  
  const {item_count, title} = props;

  let badge = item_count > 0 ? <NewItemsBadge count={item_count} /> : '';

  return (
      <span>{title}{badge}</span>
  );
}
export default Folder;