import React from 'react';


/**
 *  Phone
 */
const PhoneNumber = (props) => {

    //let islinked = props.makelink
    let phone = <span className="phone">Puh: {props.number}</span>;

    return (
      <span>
         {props.makelink ?
          <a href={`tel:${props.number}`}>{phone}</a>
          : <span>{phone}</span>
        }
        </span>
      );
}

/**
 *  Pelsu pelastussuunnitelma
 */
const PelsuLink = (props) => {
  if (props.url)

    return (
      <a href={props.url} title="Taloyhtiön Pelastussuunnitelma">
        Pelastussuunnitelma
      </a>
      );

  return "";
}

export { 
  PhoneNumber,
  PelsuLink
};
