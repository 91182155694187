import axios from 'axios';
import {APIpath} from '../shared/util';
import * as actionTypes from '../actions/actionTypes';

class AttachmentApi {

  // Huoltopyyntölomakkeen liitteen uploadaus.
  // Normaalisti tehtäisiin action-pyynnöt action creator-puolelta,
  // mutta koska tarvitaan upload progress, tehdään se poikkeuksellisesti täältä.
  static uploadAttachment_api(formdata, dispatch, actiontype) {

    return axios.post(`${APIpath}attachments`,
      formdata,
      {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress: function(progressEvent) {

            var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            dispatch({type: actionTypes[actiontype], payload: percentCompleted});

          }
      }
    )
      .then(response => {
        dispatch({type: actionTypes[actiontype], payload: 0});
        return response;
      })
      .catch(error => {
        throw(error);
      });
  }

  // Liitetiedoston poisto
  static deleteAttachment_api(id) {

    return axios.delete(`${APIpath}attachments/${id}`)
    .then(response => response)
    .catch(error => { throw(error);});

  }

}
export default AttachmentApi;
