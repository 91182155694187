import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {manualLogout} from '../../actions/authActions';

class LogoutView extends Component {

  render() {
    
    let renderHTML = (
      <Redirect to="/"/>
    );

    return (
      <div>{renderHTML}</div>
    )
  }

  UNSAFE_componentWillMount() {

    console.log("[Logout.js] Do logout");
    this.props.dispatch(manualLogout());
    

  }


}

const mapStateToProps = (state) => {
  
  return {
    isAuthenticated : state.auth.isAuthenticated,
    error : state.auth.error
  }
};



export default connect(mapStateToProps)(LogoutView);
