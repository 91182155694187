/**
 *  Dokumenttien listauskomponentti, listataan kansiottai.
 * Vastaava kuin DocumentList,paitti että artikkelit odotetaan kansioittain.
 * Näytää kansiot, ja tulostaa <DocumentList>
 * 
 * Jokseenkin sekavan oloinen setti. Anygay, tehty tän idiksen tiimoilta:
 * @see https://tylermcginnis.com/react-router-nested-routes/
 * 
 */

import React, { Component } from 'react';
import { NavLink, Route, Redirect } from 'react-router-dom'
import DocumentList from './DocumentList';
import Folder from './Folder';


// Tsekkaa listasta folderSlug perusteella sopivat artikkelit,
// ja passaa ne DocumentList komponentille tulostettavaksi.
const FolderDocumentList = (props) => { 

  const {match, items} = props;

  if (!items.length)
    return '';

  const theItem = items.find((item) => { 
    return item.folder.slug === match.params.folderSlug 
  });

  if (typeof theItem === 'undefined' )
    theItem = items.first();

  return ( <DocumentList {...props} items={theItem.articles}/> )
}

class FolderedDocumentlist extends Component {


  renderFolders() {

    const {items, match} = this.props;

    if (!items.length)
      return '';    

    let classes = ['btn', 'btn-primary'];

    // Luupataan kansiot, tulostetaan linkit
    return items.map((item, index)=>{
      // TODO: artikkelien määrän näyttämine harhaanjohtava, jos näytetään artikkelien määrä. Pitäis varmaan näyttää lukemattomien määrä, kuten valikossa.
      let count = 0; // item.articles.length;
      return (
        <NavLink key={index} to={`${match.url}/${item.folder.slug}`} className={classes.join(' ')} activeClassName="active">
          <Folder            
              title = {item.folder.title}
              item_count={count}
            />
        </NavLink>
      )
    })

  }

  renderDocuments() {

    // Kun <Folder/> tulee klikatuksi, slugi löytyy routesta.
    // Tulostetaan tällöin kansion dokumenttilista.
    const { match, ...listProps } = this.props;

    return <Route path={`${match.path}/:folderSlug`} render={ (props) => <FolderDocumentList {...props} {...listProps}/> }/>

  }

  render() {

    
    // Ohjataan ekaan kansioon
    const {items, match} = this.props;

    if (!items.length)
      return ( <p>Ei dokumentteja</p> );

    if (this.props.match.isExact) {
      
      let item = items.find(e => true); // = items.first()
      if (typeof item !== 'undefined')
        return <Redirect to={`${match.url}/${item.folder.slug}`}/>

    }
    
    return (

      <div className="FolderedDocumentList">
        <div className="folders btn-group">
            {this.renderFolders()}
        </div>
        {this.renderDocuments()}
      </div>
    )
  }
}

export default FolderedDocumentlist;
