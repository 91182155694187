import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../UI/Input/Input';
import {authAsBoardMember,
        inputChanged} from '../../actions/authActions';
import { Redirect } from 'react-router-dom';

class BoardMemberAuthorizationView extends Component {

  // FORMIN LÄHETYS
  formSubmitHandler = (event) => {
    event.preventDefault();
    // console.log("submitting form");
    const formData = {};

    for (let formElementIdentifier in this.props.form) {
      formData[formElementIdentifier] = this.props.form[formElementIdentifier].elementconfig.value;
    }

    let credentials = {
      ...formData,
      id: this.props.housing_company_id
    }

    this.props.dispatch(authAsBoardMember(credentials));

  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.props.dispatch(inputChanged('boardauth', event.target.value, inputIdentifier));
  }

  showHousingCompanyName = () => {

    if (this.props.housing_company_id) {
      return (
        <p>{this.props.housing_company_name}</p>
      );
    }

    return null;
  }

  render() {

    const formElementsArray = [];
    for (let key in this.props.form) {
      formElementsArray.push({
        id : key,
        config: this.props.form[key]
      })
    }

    const form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementtype={formElement.config.elementtype}
        elementconfig={formElement.config.elementconfig}
        value={formElement.config.elementconfig.value}
        label = {formElement.config.label}
        changed = {(event) => this.inputChangedHandler(event, formElement.id)}
        invalid = {!formElement.config.valid}
        shouldValidate = { formElement.config.validation }
        touched = { formElement.config.touched } />
    ));

    let renderHTML = (
      <div>
        { this.showHousingCompanyName() }
        <h1 className="page-title">Tunnistaudu hallituksen jäseneksi</h1>
        <article className="card">

          <form onSubmit={this.formSubmitHandler} className="login">
            {form}
          </form>

          </article>
      </div>

    );

    if (this.props.isAuthenticated) {
        renderHTML = (
        <Redirect to="/"/>
        );
    }

    return (
      <div>{renderHTML}</div>
    )
  }

}

const mapStateToProps = (state) => {

  return {
  
    isAuthenticated : state.myinfo.currentUser.board_member,
    error : state.auth.error,
    form : state.auth.form_board_auth,
    // Kirjautuessa kytketään taloyhtiöön.
    housing_company_id : state.myinfo.currentUser.id,
    housing_company_name : state.myinfo.currentUser.name
  }
};



export default connect(mapStateToProps)(BoardMemberAuthorizationView);
