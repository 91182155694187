import * as actionTypes from './actionTypes';
import maintenanceRequestApi from '../api/maintenanceRequestApi';

export function loadMaintenanceRequests(id) {

  // make async call to api, handle promise, dispatch action when promise is resolved
  return function(dispatch) {

    dispatch({type: actionTypes.LOAD_MAINTENANCE_REQUESTS_FETCH});
    return maintenanceRequestApi.fetchMaintenanceRequests(id).then(payload => {

      return dispatch({type: actionTypes.LOAD_MAINTENANCE_REQUESTS_SUCCESS, payload});
    }).catch(error => {
      console.log("ERROR: ", error);
      return dispatch({type: actionTypes.LOAD_MAINTENANCE_REQUESTS_ERROR, error});
      
    });
  };
}
