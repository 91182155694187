import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../UI/Input/Input';
import Preloader from '../UI/Preloader/Preloader';
import {requestLoginEmail,
        inputChanged,
        getAccessTokenWithEmailToken } from '../../actions/authActions';
import { Link } from 'react-router-dom';
import DOMNotification from '../UI/Notification/DOMNotification';


class LoginWithEmailView extends Component {
  // Tullaan ehkä linkillä sisään, missä autentikointitiedot. 
  // Koitetaan kirjata samantien sisälle. /sähköpostikirjautumine
  // Jos ei tuukkaa, ni eipä siin hittoikaa, näytetään kenttä millä tilataan 
  // tokeni sähköpostiin. Samalla muutetaan tila uottamaan tuota tokenia
  UNSAFE_componentWillMount() {
    
    if (typeof this.props.match.params.email !== 'undefined'
      && typeof this.props.match.params.token !== 'undefined') {


        if (this.props.match.params.token && this.props.match.params.email) {
          this.props.dispatch(getAccessTokenWithEmailToken({email: decodeURIComponent(this.props.match.params.email), token : decodeURIComponent(this.props.match.params.token) }));
        }
      }
    

  }


  // FORMIN LÄHETYS
  formSubmitHandler = (event) => {
    event.preventDefault();
    // console.log("submitting form");
    const formData = {};

    for (let formElementIdentifier in this.props.form) {
      if (this.props.form[formElementIdentifier].isVisible !== false) {
        formData[formElementIdentifier] = this.props.form[formElementIdentifier].elementconfig.value;
      }
    }

    // Pelkkä email osoite lomakkeella, lähetetää passu    
    if (typeof formData.token === 'undefined')
        this.props.dispatch(requestLoginEmail(formData));

    // Mail ja login, lähetetään kirjautuminen passulla
    else
        this.props.dispatch(getAccessTokenWithEmailToken(formData));

  }

  

  inputChangedHandler = (event, inputIdentifier) => {
    this.props.dispatch(inputChanged('email_login', event.target.value, inputIdentifier));
  }

  showHousingCompanyName = () => {

    if (this.props.housing_company_id) {
      return (
        <p>{this.props.housing_company_name}</p>
      );
    }

    return null;
  }

  render() {

    

    const formElementsArray = [];
    for (let key in this.props.form) {
      formElementsArray.push({
        id : key,
        config: this.props.form[key]
      })
    }

    const form = formElementsArray.map(formElement => (
      <Input
        key={formElement.id}
        elementtype={formElement.config.elementtype}
        elementconfig={formElement.config.elementconfig}
        value={formElement.config.elementconfig.value}
        label = {formElement.config.label}
        changed = {(event) => this.inputChangedHandler(event, formElement.id)}
        invalid = {!formElement.config.valid}
        isVisible = {formElement.config.isVisible !== false}
        shouldValidate = { formElement.config.validation }
        touched = { formElement.config.touched } />
    ));


    let renderForm = (
        <form onSubmit={this.formSubmitHandler} className="login login-with-email">
          {form}

          <br />
            <Link to="/auth/register/" className="no-decoration centered">
              Rekisteröidy &raquo;
            </Link>
        </form>
    );

    let notification = '';

    if (this.props.emailLoginSentSuccess) {

       notification = <DOMNotification
              type="success"
              title="Sait sähköpostia"
             // message = "Kirjaudu sisään klikkaamalla linkkiä sähköpostistasi."
             message = "Kirjaudu sisään sähköpostiin saamallasi salasanalla."
              />
              
    } else if ( this.props.logginWithEmailToken ) {
      renderForm = '';
      notification = <DOMNotification
              type="success"
              title="Kirjaudutaan..."
              />

    }

    let renderLoader = '';
    if (this.props.showPreloader) { 
      renderLoader = <Preloader/>
    }

    return (
      <div>
        { this.showHousingCompanyName() }

        <h1 className="page-title">Kirjaudu sisään</h1>
        <article className="card">

            {notification}
            {renderLoader}            
            {renderForm}

            
          </article>

      </div>
    )
  }

}

const mapStateToProps = (state) => {

  return {
    redirect : state.auth.redirect,
    isAuthenticated : state.auth.isAuthenticated,
    showPreloader : state.auth.isAuthFetching,
    emailLoginSentSuccess : state.auth.emailLoginSentSuccess,
    logginWithEmailToken : state.auth.logginWithEmailToken,
    error : state.auth.error,
    form : state.auth.form_email_login,
    users : state.myinfo.myinfo.users,
    // Kirjautuessa kytketään taloyhtiöön.
    housing_company_id : state.myinfo.user_reference.id,
    housing_company_name : state.myinfo.user_reference.name
  }
};



export default connect(mapStateToProps)(LoginWithEmailView);
