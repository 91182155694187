import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
//import { PelsuLink } from '../Helpers';
//import Page_Bulletin from './Page_Bulletin';
//import Page_News from './Page_News';
import {isAuthenticated} from '../../actions/authActions';
import CurrentUser from '../UI/CurrentUser';
import Menuitems from '../Menuitems';
import Preloader from '../UI/Preloader/Preloader';


class Page_Home extends Component {
  componentDidMount() {
    // Kahotaan onko localstoragessa token
    this.props.dispatch(isAuthenticated());
  }
  loader() {
    if (this.props.isLoading) {
      // rendataan syrjään, jos on jo jotain artikkeleita mitä näyttää
      return <Preloader absolute={ this.props.articles }/>
    }
  }

  renderContent() {
    /*
    <PelsuLink url={this.props.pelsuURL}/>
    <Page_News />
    <Page_Bulletin />
    */

  
      return (
          <div>
            
            <CurrentUser/>
            {this.loader()}
            { this.props.isLoading ? '' : <Menuitems className="front-menu" /> }
            
          </div>
      )
  }

  render() {

    // Mitä etusivulla, kun ei kirjautuneena? Valikkohan siinä saisi olla kanssa iha normaalisti.
    //if (!this.props.currentUserID)
    //  <Redirect to='/settings' />
    return this.renderContent()
  }
/*
  componentDidMount() {
    this.props.dispatch(loadBulletin(this.props.currentUserID));
  }
*/
}

const mapStateToProps = (state) => {
  return {
    isLoading: !state.myinfo.currentUser || state.myinfo.isLoading,
    currentUserID : state.myinfo.currentUser ? state.myinfo.currentUser.id : false,
    disableDownloads : state.app.online ? false : true,
  }
};

export default connect(mapStateToProps)(Page_Home);
