import * as actionTypes from '../actions/actionTypes.js'
import { createReducer } from '../store/Utilities'
import { toast } from 'react-toastify';

const initialState = {
  myinfo : {
    id : "",
    email : "",
    firstname : "",
    lastname : "",
    phone : "",
    users : []
  },
  error : false,
  currentUser : {
    id : "",
    hash_id : "",
    company : "",

  },
  // Kun tulee taloyhtiön linkistä kirjautumaan tai rekisteröitymään,
  // näiden avulla kytketään omiin usereihin
  user_reference: {
    id : '',
    hash_id : '',
    name : ''
  },

  userLoaded : false,
  isLoading : false,
}


export default createReducer(initialState, {
  [actionTypes.LOGOUT_MYINFO](state) {
    return {
      ...initialState
    };
  },
  [actionTypes.MYINFO_EXISTS_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true
    }
  },
  [actionTypes.MYINFO_EXISTS_ASYNC.SUCCESS](state, action) {

    return {
      ...state,
      isLoading: false
    }
  },
  [actionTypes.MYINFO_EXISTS_ASYNC.ERROR](state) {
    return {
      ...state,
      isLoading: false
    }
  },
  [actionTypes.LOAD_MYINFO_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true
    }
  },
  [actionTypes.LOAD_MYINFO_ASYNC.SUCCESS](state, action) {

    return {
      ...state,
      myinfo: action.myinfo,
      isLoading: false
    }
  },
  [actionTypes.LOAD_MYINFO_ASYNC.ERROR](state) {
    return {
      ...state,
      isLoading: false
    }
  },
  [actionTypes.ADD_TO_MY_USERS_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true
    }
  },
  [actionTypes.ADD_TO_MY_USERS_ASYNC.SUCCESS](state, action) {
    toast.success("Talohtiö lisätty!");
    const new_myinfo = state.myinfo;
    new_myinfo.users = action.myusers.data.data;
    //console.log("MITÄHÄ TULI MY USERS", action, new_myinfo.users);
    return {
      ...state,
      myinfo: new_myinfo,
      isLoading: false
    }
  },
  [actionTypes.ADD_TO_MY_USERS_ASYNC.ERROR](state) {
    return {
      ...state,
      isLoading: false
    }
  }, 
  [actionTypes.REMOVE_FROM_MY_USERS_CLICKED](state) {
    
    return {
      ...state
    }
  },   
  [actionTypes.REMOVE_FROM_MY_USERS_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true
    }
  },
  [actionTypes.REMOVE_FROM_MY_USERS_ASYNC.SUCCESS](state, action) {
    toast.success("Talohtiö poistettu.");
    const new_myinfo = state.myinfo;
    new_myinfo.users = action.myusers.data.data;
    //console.log("MITÄHÄ TULI MY USERS", action, new_myinfo.users);
    return {
      ...state,
      myinfo: new_myinfo,
      isLoading: false
    }
  },
  [actionTypes.REMOVE_FROM_MY_USERS_ASYNC.ERROR](state) {
    // toast.error("Ei onnistunut, hetki");
    return {
      ...state,
      isLoading: false
    }
  },
  [actionTypes.SET_CURRENT_USER.PENDING](state) {
    return {
      ...state,
      isLoading: true
    }
  },  
  [actionTypes.SET_CURRENT_USER.SUCCESS](state, action) {
      // console.log(" ACTION SET_CURRENT_USER.SUCCESS ", action);


      // Todo miks tämä pannaan localstorageen? 
      // teemu 4.9.2019
      localStorage.setItem("userID", action.user.id);

      return {
        ...state,
        isLoading : false,
        currentUser : action.user, /*{
          id : action.user.id,
          hash_id : action.user.hash_id,
          company :  action.user.company,
        },*/
        userLoaded : true
      }
      
  },
  // TODO: tämä jos tapahtuu, eli käyttäjällä eijo yhtää taloyhtiöö, pitäis
  // joku fiksu sivu näyttää ja ohjata. Ohjeet tjsp?
  [actionTypes.SET_CURRENT_USER.ERROR](state) {
    return {
      ...state,
      isLoading : false,
      currentUser : initialState.currentUser,
      userLoaded : initialState.userLoaded,
    }
  },

  [actionTypes.UNSET_CURRENT_USER](state) {

      return {
        ...state,
        currentUser : initialState.currentUser,
        userLoaded : initialState.userLoaded,
      }
  },

  [actionTypes.GET_USER_REFERENCE_ASYNC.PENDING](state, action) {
    return {
      ...state,
      isLoading: true,
      error: false,
      user_reference : {
        id : '',
        hash_id : action.hash_id,
        name : '',
      }
    }
  },
  [actionTypes.GET_USER_REFERENCE_ASYNC.SUCCESS](state, action) {
// console.log("GET_USER_REFERENCE_ASYNC.SUCCESS", action.referencedUser.data);
    return {
      ...state,
      isLoading: false,
      error: false,
      user_reference : {
        id : action.referencedUser.id,
        hash_id : action.referencedUser.hash_id,
        name : action.referencedUser.name,
      }
    }
  },
  [actionTypes.GET_USER_REFERENCE_ASYNC.ERROR](state, action) {
    //console.log("GET_USER_REFERENCE_ASYNC.ERROR", action);
    return {
      ...state,
      isLoading: false,
      error: action.error,
      user_reference : {
        id : '',
        hash_id : '',
        name : '',
      }
    }
  },

  [actionTypes.SET_USER_REFERENCE](state, action) {

    return {
      ...state,
      user_reference : {
        id : action.id,
        hash_id : action.hash_id,
        name : action.name,
      }
    }
  },

});


const findUserByID = (id, state) => {
  return state.myinfo.users.find(function (obj) { return obj.id === id; });
}
/*
const myInfoReducer = (state = initialState, action) => {

  switch(action.type) {
    case actionTypes.LOAD_MY_INFO_SUCCESS :
      return {
        ...state,
        myinfo: action.payload.data.data,
        error: false,
    }
    case actionTypes.LOAD_MY_INFO_ERROR :
      return {
        ...state,
        userLoaded : false,
        error: action.error
      }

    case actionTypes.SET_MY_INFO_CURRENT_USER :
      let obj = findUserByID(action.id, state);
      if (obj) {

        localStorage.setItem("userID", action.id);

        return {
          ...state,
          currentUser : obj,
          userLoaded : true
        }
      }
      return state;

    default: return state;

  }

};

export default myInfoReducer;
*/
