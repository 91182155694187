import axios from 'axios';
import {APIpath, getUserID} from '../shared/util';

class DocumentsApi {

  static getArticles() {
    return axios.get(`${APIpath}users/${getUserID()}/documents`)
    .then(response => response)
    .catch(error => {
      throw(error);
    });
  }
}
export default DocumentsApi;
