import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import {Provider} from "react-redux";
import configureStore from './store/configureStore';

import {setupAPIInterceptors, connectionMonitor} from './shared/util';

import { createBrowserHistory } from 'history'

const history = createBrowserHistory();
const store = configureStore(history);

// Asettaa state.app.online true tai false jos yhteys pätkii.
connectionMonitor(store);
// Nappaa 401 ja ohjaa loginiin.
setupAPIInterceptors(store);

ReactDOM.render((
<Provider store={store}>
  <ConnectedRouter history={history}> 
     <App/>
  </ConnectedRouter>
</Provider>
), document.getElementById('root'))

// Wanha create react app v3
// registerServiceWorker();
// serviceWorkerRegistration.unregister();
console.log("servicewoker.register");
serviceWorkerRegistration.register({
  onUpdate: function(registration) {
    console.log("Serviceworker updated, refreshing");
    window.location.reload(true);
  },
  onSuccess: function(registration) {
    console.log("Serving cached version", registration);
  }
});