import { createAsyncTypes } from '../store/Utilities'

// App
export const APP_CONNECTION_ONLINE = 'CONNECTION_ONLINE';
export const APP_CONNECTION_OFFLINE = 'CONNECTION_OFFLINE';
export const APP_RATE_LIMIT_EXCEEDED = 'APP_RATE_LIMIT_EXCEEDED';
export const APP_ARTICLE_CLICKED = 'APP_ARTICLE_CLICKED';

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const OPEN_MENU = 'OPEN_MENU';


// Authentication
export const UNAUTHORIZED_REQUEST = 'UNAUTHORIZED_REQUEST';

// Nääkö eijo ies käyutössä?:O
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';

// Localstoragesta löytyvä access token
export const NO_STORED_ACCESS_TOKEN = 'NO_STORED_ACCESS_TOKEN';
export const STORED_ACCESS_TOKEN_EXPIRED = 'STORED_ACCESS_TOKEN_EXPIRED';
export const STORED_ACCESS_TOKEN_FOUND = 'STORED_ACCESS_TOKEN_FOUND';
export const STORED_ACCESS_TOKEN_AUTH_ASYNC = createAsyncTypes('STORED_ACCESS_TOKEN_AUTH');

export const LOGOUT = 'LOGOUT';
export const MANUAL_LOGOUT = 'MANUAL_LOGOUT';
export const LOGIN_FORM_INPUT_CHANGED = 'LOGIN_FORM_INPUT_CHANGED';
export const EMAIL_LOGIN_FORM_INPUT_CHANGED  = 'EMAIL_LOGIN_FORM_INPUT_CHANGED';
export const BOARD_AUTH_FORM_INPUT_CHANGED = 'BOARD_AUTH_FORM_INPUT_CHANGED';
export const SHAREHOLDER_AUTH_FORM_INPUT_CHANGED = 'SHAREHOLDER_AUTH_FORM_INPUT_CHANGED';

// TODO tästä rekisteröitymistoimenpiteestä pitäis vaihtaa USER sanan
// tilalle INDIVIDUAL
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const REGISTER_FORM_INPUT_CHANGED = 'REGISTER_FORM_INPUT_CHANGED';

export const GET_USER_REFERENCE_ASYNC = createAsyncTypes('GET_USER_REFERENCE');
export const SET_USER_REFERENCE = 'SET_USER_REFERENCE';
/*export const GET_USER_REFERENCE = 'GET_USER_REFERENCE';
export const GET_USER_REFERENCE_SUCCESS = 'GET_USER_REFERENCE_SUCCESS';
export const GET_USER_REFERENCE_ERROR = 'GET_USER_REFERENCE_ERROR';
*/

export const ADD_TO_MY_USERS_ASYNC = createAsyncTypes('ADD_TO_MY_USERS');
/*export const ADD_TO_MY_USERS_ASYNC_PENDING = 'ADD_TO_MY_USERS_ASYNC_PENDING';
export const ADD_TO_MY_USERS_ASYNC_SUCCESS = 'ADD_TO_MY_USERS_ASYNC_SUCCESS';
export const ADD_TO_MY_USERS_ASYNC_ERROR = 'ADD_TO_MY_USERS_ASYNC_ERROR';
*/
export const REMOVE_FROM_MY_USERS_ASYNC = createAsyncTypes('REMOVE_FROM_MY_USERS');
export const REMOVE_FROM_MY_USERS_CLICKED = 'REMOVE_FROM_MY_USERS_CLICKED';

// Request login email
export const REQUEST_LOGIN_EMAIL_ASYNC = createAsyncTypes('REQUEST_LOGIN_EMAIL');


// Get access Token with username + password
export const LOAD_ACCESS_TOKEN = 'LOAD_ACCESS_TOKEN';
export const LOAD_ACCESS_TOKEN_SUCCESS = 'LOAD_ACCESS_TOKEN_SUCCESS';
export const LOAD_ACCESS_TOKEN_ERROR = 'LOAD_ACCESS_TOKEN_ERROR';

// Get access Token with email + token
export const GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN = 'GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN';
export const GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_SUCCESS = 'GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_SUCCESS';
export const GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_ERROR = 'GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_ERROR';


// Refresh Access Token
// Authenticate as board member
export const REFRESH_ACCESS_TOKEN_ASYNC = createAsyncTypes('REFRESH_ACCESS_TOKEN');
export const NO_REFRESH_TOKEN = 'NO_REFRESH_TOKEN';
/*
export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
export const REFRESH_ACCESS_TOKEN_SUCCESS = 'REFRESH_ACCESS_TOKEN_SUCCESS';
export const REFRESH_ACCESS_TOKEN_ERROR = 'REFRESH_ACCESS_TOKEN_ERROR';
*/

export const OAUTH_LOGIN_POPUP = createAsyncTypes('OAUTH_LOGIN_POPUP');
export const OAUTH_LOGOUT_POPUP = createAsyncTypes('OAUTH_LOGOUT_POPUP');

// Set access Token from Oauth
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_ACCESS_TOKEN_SUCCESS = 'SET_ACCESS_TOKEN_SUCCESS';
export const SET_ACCESS_TOKEN_ERROR = 'SET_ACCESS_TOKEN_ERROR';

// Authenticate as board member
export const BOARD_MEMBER_AUTH_ASYNC = createAsyncTypes('BOARD_MEMBER_AUTH');
// Authenticate as shareholder
export const SHAREHOLDER_AUTH_ASYNC = createAsyncTypes('SHAREHOLDER_AUTH');

// MyInfo
export const LOGOUT_MYINFO = 'LOGOUT_MYINFO';
export const LOAD_MYINFO_ASYNC = createAsyncTypes('LOAD_MYINFO');
export const MYINFO_EXISTS_ASYNC = createAsyncTypes('MYINFO_EXISTS');

//export const LOAD_MY_INFO = 'LOAD_MY_INFO';
//export const LOAD_MY_INFO_SUCCESS = 'LOAD_MY_INFO_SUCCESS';
//export const LOAD_MY_INFO_ERROR = 'LOAD_MY_INFO_ERROR';
export const SET_CURRENT_USER = createAsyncTypes('SET_CURRENT_USER');

export const UNSET_CURRENT_USER = 'UNSET_CURRENT_USER';

export const CURRENT_USER_CHANGED = 'CURRENT_USER_CHANGED';

// User
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_INVALID_DATA = 'LOAD_USER_INVALID_DATA';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';

// Bulletin
export const LOGOUT_BULLETIN = 'LOGOUT_BULLETIN';
export const LOAD_BULLETIN_ASYNC = createAsyncTypes('LOAD_BULLETIN');

//export const LOAD_BULLETIN_FETCH = 'LOAD_BULLETIN_FETCH';
//export const LOAD_BULLETIN_SUCCESS = 'LOAD_BULLETIN_SUCCESS';
//export const LOAD_BULLETIN_ERROR = 'LOAD_BULLETIN_ERROR';

// Osakkaiden arkisto
export const LOGOUT_SHAREHOLDER_ARCHIVE = 'LOGOUT_SHAREHOLDER_ARCHIVE';
export const LOAD_SHAREHOLDER_ARCHIVE_ASYNC = createAsyncTypes('LOAD_SHAREHOLDER_ARCHIVE');

// Hallituksen arkisto
export const LOGOUT_BOARD_ARCHIVE = 'LOGOUT_BOARD_ARCHIVE';
export const LOAD_BOARD_ARCHIVE_ASYNC = createAsyncTypes('LOAD_BOARD_ARCHIVE');

// MaestroNG iframe - ostolaskut näkymä
export const LOAD_MAESTRONG_IFRAME_ASYNC = createAsyncTypes('LOAD_MAESTRONG_IFRAME');


// News
export const LOGOUT_NEWS = 'LOGOUT_NEWS';
export const LOAD_NEWS_ASYNC = createAsyncTypes('LOAD_NEWS');
export const SHOULD_REFRESH_NEWS = 'SHOULD_REFRESH_NEWS';

// Documents
//Using ASYNC as a convention to know that I'll have three types.
export const LOGOUT_DOCUMENTS = 'LOGOUT_DOCUMENTS';
export const LOAD_DOCUMENTS_ASYNC = createAsyncTypes('LOAD_DOCUMENTS')

//export const LOAD_DOCUMENTS_FETCH = 'LOAD_DOCUMENTS_FETCH';
//export const LOAD_DOCUMENTS_SUCCESS = 'LOAD_DOCUMENTS_SUCCESS';
//export const LOAD_DOCUMENTS_ERROR = 'LOAD_DOCUMENTS_ERROR';

// Maintenance Requests
export const LOGOUT_MAINTENANCE_REQUESTS = 'LOGOUT_MAINTENANCE_REQUESTS';
export const LOAD_MAINTENANCE_REQUESTS_FETCH = 'LOAD_MAINTENANCE_REQUESTS_FETCH';
export const LOAD_MAINTENANCE_REQUESTS_SUCCESS = 'LOAD_MAINTENANCE_REQUESTS_SUCCESS';
export const LOAD_MAINTENANCE_REQUESTS_ERROR = 'LOAD_MAINTENANCE_REQUESTS_ERROR';

// Maintenance Request form
export const MAINTENANCE_REQUEST_FORM_SET_INITIAL_VALUES = 'MAINTENANCE_REQUEST_FORM_SET_INITIAL_VALUES';
export const MAINTENANCE_REQUEST_FORM_INPUT_CHANGED = 'MAINTENANCE_REQUEST_FORM_INPUT_CHANGED';
export const MAINTENANCE_REQUEST_FORM_SEND = 'MAINTENANCE_REQUEST_FORM_SEND';
export const MAINTENANCE_REQUEST_FORM_SENT_SUCCESS = 'MAINTENANCE_REQUEST_FORM_SENT_SUCCESS';
export const MAINTENANCE_REQUEST_FORM_SENT_ERROR = 'MAINTENANCE_REQUEST_FORM_SENT_ERROR';
export const MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS = 'MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS';
export const MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_SUCCESS = 'MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_SUCCESS';
export const MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_ERROR = 'MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_ERROR';
export const MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_PROGRESS = 'MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_PROGRESS';
export const MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT = 'MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT';
export const MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_SUCCESS = 'MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_SUCCESS';
export const MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_ERROR = 'MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_ERROR';
export const MAINTENANCE_REQUEST_FORM_RESET = 'MAINTENANCE_REQUEST_FORM_RESET';

// Contact Form
export const CONTACT_FORM_SET_INITIAL_VALUES = 'CONTACT_FORM_SET_INITIAL_VALUES';
export const CONTACT_FORM_INPUT_CHANGED = 'CONTACT_FORM_INPUT_CHANGED';
export const CONTACT_FORM_SEND = 'CONTACT_FORM_SEND';
export const CONTACT_FORM_SENT_SUCCESS = 'CONTACT_FORM_SENT_SUCCESS';
export const CONTACT_FORM_SENT_ERROR = 'CONTACT_FORM_SENT_ERROR';
export const CONTACT_FORM_RESET = 'CONTACT_FORM_RESET';

// Add User Form
export const ADD_USER_FORM_SET_INITIAL_VALUES = 'ADD_USER_FORM_SET_INITIAL_VALUES';
export const ADD_USER_FORM_INPUT_CHANGED = 'ADD_USER_FORM_INPUT_CHANGED';
export const ADD_USER_FORM_SEND = 'ADD_USER_FORM_SEND';
export const ADD_USER_FORM_SENT_SUCCESS = 'ADD_USER_FORM_SENT_SUCCESS';
export const ADD_USER_FORM_SENT_ERROR = 'ADD_USER_FORM_SENT_ERROR';
export const ADD_USER_FORM_RESET = 'ADD_USER_FORM_RESET';

// Attachments
export const UPLOAD_ATTACHMENTS = 'UPLOAD_ATTACHMENTS';
export const UPLOAD_ATTACHMENTS_SUCCESS = 'UPLOAD_ATTACHMENTS_SUCCESS';
export const UPLOAD_ATTACHMENTS_ERROR = 'UPLOAD_ATTACHMENTS_ERROR';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_ERROR = 'DELETE_ATTACHMENT_ERROR';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
