import * as actionTypes from '../actions/actionTypes.js'
import {addFormInitialContactValues,
        formInputChanged,
        resetForm } from '../shared/util'
import { toast } from 'react-toastify'


const initialState = {
  attachments : [],
  isLoading: false,
  isSending: false,
  formSentSuccess: false,
  attachment_uploadProgress : 0,
  isAttachmentsUploading: false,
  error: null,
  formIsValid : false,
  attachment_is_waiting_for_delete_response: false,
  form : {
    title : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : 'Mitä asiasi koskee?',
        //value : 'Testiotsikko',
        value : '',
      },
      label : 'Otsikko *',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    },
    message : {
      elementtype : 'textarea',
      elementconfig : {
        type : 'textarea',
        placeholder : 'Kerro lyhyt vikakuvaus',
        //value : 'Tämä on viesti',
        value : '',
      },
      label : 'Seloste *',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    },
    contact_name : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : '',
        value : '',
      },
      label : 'Nimi *',
      validation : {
        required : false
      },
      valid : false,
      touched : false,
    },
    contact_email : {
      elementtype : 'email',
      elementconfig : {
        type : 'email',
        placeholder : '',
        value : '',
      },
      label : 'Sähköpostiosoite *',
      validation : {
        required : false,
        isEmail : true
      },
      valid : false,
      touched : false,
    },
    contact_phone : {
      elementtype : 'tel',
      elementconfig : {
        type : 'tel',
        placeholder : '',
        value : ''
      },
      label : 'Puhelin *',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    },
    apartment : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : '',
        value : ''
      },
      label : 'Asunto / tila *',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    }
  },


}

const maintenanceRequestFormReducer = (state = initialState, action) => {

  switch(action.type) {

    case actionTypes.MAINTENANCE_REQUEST_FORM_SET_INITIAL_VALUES :
      return addFormInitialContactValues(state, action);

    case actionTypes.MAINTENANCE_REQUEST_FORM_INPUT_CHANGED :
      const { form, formIsValid } = formInputChanged(state.form, action);
      return {
        ...state,
        form : form,
        formIsValid : formIsValid
      }

    case actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS :
      return {
        ...state,
        isAttachmentsUploading: true,
        error: false
      }

    case actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_PROGRESS :
      return {
        ...state,
        attachment_uploadProgress: action.payload
      }

    case actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_SUCCESS :
      return {
        ...state,
        attachments: state.attachments.concat(action.payload.data.data),
        isAttachmentsUploading: false,
        error: false
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_ERROR:
      toast.error("Liitteen lataaminen ei onnistunut.");
      if (action.error) {
        console.log("MAINTENANCE_REQUEST_FORM_UPLOAD_ATTACHMENTS_ERROR", action.error);
      }
      return {
        ...state,
        isAttachmentsUploading: false,
        error: action.error
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT:
      return {
        ...state,
        attachment_is_waiting_for_delete_response: true,
        attachments : state.attachments.filter((el) => el.id !== action.id)
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachment_is_waiting_for_delete_response: false
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_ERROR:
      toast.error("Liitteen poistaminen ei onnistunut");
      if (action.error) {
        console.log("MAINTENANCE_REQUEST_FORM_DELETE_ATTACHMENT_ERROR", action.error);
      }
      return {
        ...state,
        attachment_is_waiting_for_delete_response: false
      }

    case actionTypes.MAINTENANCE_REQUEST_FORM_SEND:
      return {
        ...state,
        isSending : true,
        sentError: false,
        formSentSuccess: false
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_SENT_SUCCESS:
      return {
        ...state,
        isSending : false,
        sentError : false,
        formSentSuccess: true,
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_SENT_ERROR:
      toast.error("Huoltopyynnön lisäys ei onnistunut.");
      if (action.error) {
        console.log("MAINTENANCE_REQUEST_FORM_SENT_ERROR", action.error);
      }
      return {
        ...state,
        isSending : false,
        sentError : action.error
      }
    case actionTypes.MAINTENANCE_REQUEST_FORM_RESET :
      return {
        ...state,
        isSending: false,
        sentError : false,
        formSentSuccess: false,
        attachments: [],
        attachment_uploadProgress : 0,
        isAttachmentsUploading: false,
        error: false,
        formIsValid : false,
        attachment_is_waiting_for_delete_response: false,
        form: resetForm(state.form)
      }

    default:
      return state;
  }

};

export default maintenanceRequestFormReducer;
