import axios from 'axios';
import {APIpath} from '../shared/util';

class MyInfoApi {

  // Jostain syyst nää ei enää futaa - Teemu 2.11
  // static api_endpoint = APIpath + 'auth/me';
  // Alla määritetty suoraan .post() kutsuun APIpath

  static getMyInfo () {
    return axios.post(`${APIpath}auth/me`).then(response => {
      return response.data.data;
    }).catch(error => {
      throw (error);
    });
  }


}
export default MyInfoApi;
