import React from 'react';
import Attachments from './Attachments';
import { niceDate } from '../shared/util'
const Article = (props) => {

    let articleClassName = 'card';

    if (props.status) {
      articleClassName += ' has_status status_'+props.status;

    }

    if (props.message.replace(/<.+?>/g, '').length > 3)
      articleClassName += ' has_content';
    else 
      articleClassName += ' no_content';

    if ((Array.isArray(props.attachments) && props.attachments.length > 0)) {
      articleClassName += props.attachments.length > 1 ? ' has_attachments' : ' has_attachment';
    } else  {
      articleClassName += ' no_attachments';
    }

    // Passataan ite artikkeli onclick handlerissä. 
    // ELi, tsekkaa ArticleList, porataan click handleri alas artikkeliin
    // saakka jostain ylhäältä. Parametrinä tulee klikattu artikkeli.
    let clickHandler = () => {
        if (typeof props.onClick === 'function')
          props.onClick(props);
    }    


    return <article className={articleClassName}>

    { getArticleIcon(props.status) }

      <h1 className="article-title" onClick={clickHandler}>{props.title}</h1>

    { getArticleMeta(props) }
      

    { getArticleContent(props.message) }


    { getAttachments({...props, onClick: clickHandler }) }

    </article>
}

const getArticleMeta = (props) => {

  if (props.showMeta !== true)
   return '';

   // Jos linkki, keksitään linkin teksti linkistä (domain)

  //  Create an anchor element (note: no need to append this element to the document)
  let link_text = 'Linkki';
  if (props.link.length > 0) {
    const url = document.createElement('a');
    //  Set href to any path
    url.setAttribute('href', props.link);
    link_text = url.hostname;
  }

  return (
  <div className="meta">
    
    {props.date.length > 0 &&
       <span className="date">{niceDate(props.date)}</span>
    }    
    {props.link.length > 0 &&
       <span className="link"><a href={props.link} target="_blank">{link_text}</a></span>
    }
  </div>
  );
}

const getArticleContent = (message) => {
  if (message.replace(/<.+?>/g, '').length > 3)
    return (<div className="content" dangerouslySetInnerHTML={{__html: message}} />)

  return "";
}
const getAttachments = (props) => {
  let has_attachments = (Array.isArray(props.attachments) && props.attachments.length > 0);


  if (has_attachments === true) {

    return (<Attachments attachments={props.attachments} elem={props.disableDownloads ? 'p' : 'a' } onClick={props.onClick}/> );
  }

  return "";
}
const getArticleIcon = (status) => {

  // Ahistavii, ei tulosteta mitään.
  // Kahotaan, jos noissa huoltopyynnöissä pitää nää olla.
  // Paa CSS..
  // T 25.10.2019
  return "";

  switch (status) {
    // Ei tulosteta mittää, pannaa vaa vää oranssii taustaa CSS:llä.
    case 'new' :
        return  ''; /*(
          <svg  fill="#f7931d" width="25" height="25" viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0V0z"/>
              <path d="M23 12l-2.44-2.78.34-3.68-3.61-.82-1.89-3.18L12 3 8.6 1.54 6.71 4.72l-3.61.81.34 3.68L1 12l2.44 2.78-.34 3.69 3.61.82 1.89 3.18L12 21l3.4 1.46 1.89-3.18 3.61-.82-.34-3.68L23 12zm-4.51 2.11l.26 2.79-2.74.62-1.43 2.41L12 18.82l-2.58 1.11-1.43-2.41-2.74-.62.26-2.8L3.66 12l1.85-2.12-.26-2.78 2.74-.61 1.43-2.41L12 5.18l2.58-1.11 1.43 2.41 2.74.62-.26 2.79L20.34 12l-1.85 2.11zM11 15h2v2h-2zm0-8h2v6h-2z"/>
          </svg>
        );      */
    case 'pending' :
    
      return (
        <svg fill="#ddd" height="25" width="25" viewBox="0 0 24 24">
            <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"/>
        </svg>
      ); 

    case 'seen' :
      return (
        <svg fill="#f7931d" height="25" width="25" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0V0z"/>
            <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/>
        </svg>
      ); 

    case 'done' :
      return (
        <svg fill="#f7931d" height="25" width="25" viewBox="0 0 24 24">
            <path fill="none" d="M0 0h24v24H0z"/>
            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
        </svg>

      ); 

    case 'in_progress' :
      return (
        <svg fill="#f7931d" height="25" width="25" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0V0z"></path>
          <path d="M22.61 18.99l-9.08-9.08c.93-2.34.45-5.1-1.44-7C9.79.61 6.21.4 3.66 2.26L7.5 6.11 6.08 7.52 2.25 3.69C.39 6.23.6 9.82 2.9 12.11c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.4-.38.4-1.01 0-1.41zm-3 1.6l-9.46-9.46c-.61.45-1.29.72-2 .82-1.36.2-2.79-.21-3.83-1.25C3.37 9.76 2.93 8.5 3 7.26l3.09 3.09 4.24-4.24-3.09-3.09c1.24-.07 2.49.37 3.44 1.31 1.08 1.08 1.49 2.57 1.24 3.96-.12.71-.42 1.37-.88 1.96l9.45 9.45-.88.89z"></path>
        </svg>
      );

    default : return "";

  }

}

export default Article;
