import React, { Component } from 'react';
import {connect} from 'react-redux';
import {PhoneNumber} from '../Helpers';
import Input from '../UI/Input/Input';
import Preloader from '../UI/Preloader/Preloader';
import DOMNotification from '../UI/Notification/DOMNotification';
import {sendContactForm,
        setContactFormInitialValues,
        resetContactForm,
        inputChanged} from '../../actions/contactFormActions';
import {Messages} from '../../shared/util';

class Page_Contact extends Component {

  // FORMIN LÄHETYS
  formSubmitHandler = (event) => {
    event.preventDefault();

    const formData = {};

    for (let formElementIdentifier in this.props.form) {
      formData[formElementIdentifier] = this.props.form[formElementIdentifier].elementconfig.value;
    }
    this.props.dispatch(sendContactForm(formData));
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.props.dispatch(inputChanged(event.target.value, inputIdentifier));
  }

  render () {

    const formElementsArray = [];
    for (let key in this.props.form) {
      formElementsArray.push({
        id: key,
        config: this.props.form[key],
      })
    }

    let form = (
      <form onSubmit={this.formSubmitHandler}>
        {formElementsArray.map(formElement => (

          <Input
            key={formElement.id}
            elementtype={formElement.config.elementtype}
            elementconfig={formElement.config.elementconfig}
            value={formElement.config.elementconfig.value}
            label = {formElement.config.label}
            changed = {(event) => this.inputChangedHandler(event, formElement.id)}
            invalid = {!formElement.config.valid}
            shouldValidate = { formElement.config.validation }
            disabled = {this.props.offline}
            touched = { formElement.config.touched } />
          ))}

        <input type="submit" className="btn btn-primary"  value="Lähetä" disabled={ !this.props.formIsValid || this.props.offline } />

      </form>
    );

    if (this.props.form_is_sending) {
      form = <Preloader inline="true" />
    }


    if (this.props.sentSuccess) {
      form = <DOMNotification
              type="success"
              title= {Messages.form.sent.title}
              message = {Messages.form.sent.message}
              clickEvent={()=>{
                this.props.dispatch(resetContactForm());

                // Alkuarvot lomakkeelle: email, nimi, puhelin
                this.props.dispatch(setContactFormInitialValues({
                  email : this.props.email,
                  firstname : this.props.firstname,
                  lastname : this.props.lastname,
                  phone : this.props.phone
                }));

              }}/>
    }

    return (
      <div>
      <h1 className="page-title">Ota yhteyttä</h1>
      
      {form}

      <section>
      {
        this.props.maintenances.map((info, index)=>{
          return (
          <article className="card" key={index}>
              <h3>{info.company}</h3>
              <div>{info.address}</div>
              <div>{info.zip} {info.city}</div>
              <div><PhoneNumber number={info.phone} makelink={true} /></div>
          </article>
          )
        })
       }
       {
         this.props.maintainers.map((info, index)=>{
           return (
           <article className="card" key={index}>
               <h3>{info.company}</h3>
               <div>{info.address}</div>
               <div>{info.zip} {info.city}</div>
               <div>  <PhoneNumber number={info.phone} makelink={true} /></div>
           </article>
           )
         })
        }

      </section>

    </div>
    )
  }

  componentDidMount () {

    // Alkuarvot lomakkeelle: email, nimi, puhelin
    this.props.dispatch(setContactFormInitialValues({
      email : this.props.email,
      firstname : this.props.firstname,
      lastname : this.props.lastname,
      phone : this.props.phone
    }));
  }

};


const MapStateToProps = (state) => {

  return {
    userdata : state.myinfo,
    maintenances : state.myinfo.currentUser.maintenances,
    maintainers : state.myinfo.currentUser.maintainers,
    userID : state.myinfo.currentUser.id,
    email : state.myinfo.myinfo.email,
    form : state.contact_form.form,
    firstname : state.myinfo.myinfo.firstname,
    lastname : state.myinfo.myinfo.lastname,
    phone : state.myinfo.myinfo.phone,
    formIsValid : state.contact_form.formIsValid,
    form_is_sending : state.contact_form.isSending,
    sentSuccess : state.contact_form.formSentSuccess,
    offline : state.app.online ? false : true
  }
}
export default connect (MapStateToProps)(Page_Contact);
