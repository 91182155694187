import * as actionTypes from './actionTypes';
import authApi from '../api/authApi';
import { setAuthorizationToken } from '../shared/util';
import { push } from 'connected-react-router';
import { createAction } from '../store/Utilities'

// https://hackernoon.com/replacing-redux-thunks-with-redux-sagas-4aa306854925
export const refreshAccessTokenActions = {
  pending: () => createAction(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.PENDING),
  success: ({access_token, expires_in}) => createAction(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.SUCCESS, { token: access_token, token_expiration: expires_in}),
  error: (error) => createAction(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.ERROR, { error }),
  cancel: () => createAction(actionTypes.NO_REFRESH_TOKEN)
}
export const storedAccessTokenAuthActions = {
  pending: () => createAction(actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.PENDING),
  success: () => createAction(actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.SUCCESS),
  error: (error) => createAction(actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.ERROR, { error })
}
export const oauthLoginPopup = {
  pending: () => createAction(actionTypes.OAUTH_LOGIN_POPUP.PENDING),
  success: () => createAction(actionTypes.OAUTH_LOGIN_POPUP.SUCCESS),
  error: (error) => createAction(actionTypes.OAUTH_LOGIN_POPUP.ERROR, { error })
}
export const oauthLogoutPopup = {
  pending: () => createAction(actionTypes.OAUTH_LOGOUT_POPUP.PENDING),
  success: () => createAction(actionTypes.OAUTH_LOGOUT_POPUP.SUCCESS),
  error: (error) => createAction(actionTypes.OAUTH_LOGOUT_POPUP.ERROR, { error })
}

export function manualLogout() {
  

  return function(dispatch) {
    dispatch(logout());
    return dispatch({type: actionTypes.MANUAL_LOGOUT});
  }
}
export function logout () {
  return function(dispatch) {

    // ei piitata miten käy, palvelin kuolettaa tokenin.
    // ei kuoleta, jos koitta vanhalla tokenilla logata ulos (fiksaa backendi palauttamaan ok).
    // Turha kuitenkaa logata ulos jos eijo ollenkaa tallessa.
    // Voi käydä joskus, halutaan kuitenkin aina tyhjentää tiedot statesta, kun ns. uloskirjataan
    let token = localStorage.getItem('jwtToken');

    if (token)
      authApi.logoutApi();

    setAuthorizationToken(null);
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('jwtToken_expiration');
    // TODO localstoragesta mydata mäkeen.
    console.log("[authActions.js] Logout, Access token invalidated.");

    dispatch({type: actionTypes.LOGOUT_MYINFO});
    dispatch({type: actionTypes.LOGOUT_BULLETIN});
    dispatch({type: actionTypes.LOGOUT_DOCUMENTS});
    dispatch({type: actionTypes.LOGOUT_NEWS});
    dispatch({type: actionTypes.LOGOUT_MAINTENANCE_REQUESTS});
    dispatch({type: actionTypes.LOGOUT_SHAREHOLDER_ARCHIVE});
    dispatch({type: actionTypes.LOGOUT_BOARD_ARCHIVE});

    return dispatch({type: actionTypes.LOGOUT});
    
  };
}



export function register (data) {

  // console.log("[authActions.js] registering...");

  return function(dispatch) {

    dispatch({type: actionTypes.REGISTER_USER });

    return authApi.registerUser(data).then(response => {
      // 1.10.2020 - rekisteröinti lähettää passun meiliin, ohjataan vaa etusivulle viesteineen.
      // ei siis kirjata tässä sisään. Kirjautuminen auth pannulla (oauth systeemein)
      dispatch({
          type: actionTypes.REGISTER_USER_SUCCESS, 
          
      });

      /*
      //
      // Login ok, asetetaan Access token ja expiration-aika millisekunteina.
      //
      //console.log("[authActions.js] Registered successfully.");

      setAuthorizationToken(response.data.access_token);
      localStorage.setItem('jwtToken', response.data.access_token);
      localStorage.setItem('jwtToken_expiration', response.data.expires_in);
      //console.log("[authActions.js] Access token stored to localStorage.");
      // setAuthenticated();
      dispatch({
          type: actionTypes.REGISTER_USER_SUCCESS, 
          token : response.data.access_token, 
          token_expiration : response.data.expires_in 
      });
      */
      return dispatch( push("/") );

    }).catch(error => {
      //console.log("[authActions.js] Failed to register user.", error);
      let error_message = ['Rekisteröityminen epäonnistui'];
     
      
      // Laravel backendi palauttaa jsonissa errors avaimella virheet.
      if (typeof error.response != 'undefined' ) {
        let error_data = error.response.data;
        for (const key of Object.keys(error_data.errors)) {
            console.log(key, error_data.errors[key]);
            error_message.push( error_data.errors[key].toString() );
        }
        
      }
      
      console.log(error_message);
      /*
      if (error.response.data.errors) {
        error_message = Object.values(error.response.data.errors).join('<br/>');
      }
      */
      return dispatch( { type: actionTypes.REGISTER_USER_ERROR, error : error_message.join(' ') });
      // setUnauthenticated();
      // throw(error);
    });

  };
}


export function requestLoginEmail(email) {
  console.log("[authActions.js] requesting login email...");

  return function(dispatch) {
    // Saga tyyppine action nimi, kun tehty 5.9.2019 :D Teemu
    // Pistä koko autentikointihommat sagoihin.
    dispatch({type: actionTypes.REQUEST_LOGIN_EMAIL_ASYNC.PENDING });

      return authApi.requestLoginEmail(email).then(response => {
  
        // Meili lähetetty
        console.log("[authActions.js] Login email sent.");
  
        return dispatch({
            type: actionTypes.REQUEST_LOGIN_EMAIL_ASYNC.SUCCESS, 
        });
  
      }).catch(error => {
        console.log("[authActions.js] Failed to send login email.", error);
        
        let error_message = [];
        let error_data = {}; 
        if (typeof error.response === 'undefined' || typeof error.response.data == 'undefined') {
          error_message = ['Palvelin ei vastaa']
        } else { 
          error_data = error.response.data;
        }

        if ( typeof error.response !== 'undefined' && 401 === error.response.status ) {
          error_message = ['Tarkista sähköpostiosoite.']
        }

        
        // Laravel backendi palauttaa jsonissa errors avaimella virheet.
        if (typeof error_data.errors !== 'undefined' && error_data.errors) {
          for (const key of Object.keys(error_data.errors)) {
              console.log(key, error_data.errors[key]);
              error_message.push( error_data.errors[key].toString() );
          }
          
        } 
        
        if (!error_message.length)
          error_message.push('Sähköpostiosoite ei ole olemassa tai muu ongelma.');
/*
        console.log("[authActions.js] Failed to register user.", error);
        let error_message = ['Rekisteröityminen epäonnistui'];
  
        // Laravel backendi palauttaa jsonissa errors avaimella virheet.
        if (error.errors) {
          for (const key of Object.keys(error.errors)) {
              console.log(key, error.errors[key]);
              error_message.push( error.errors[key].toString() );
          }
          
        }
*/

        return dispatch( { type: actionTypes.REQUEST_LOGIN_EMAIL_ASYNC.ERROR, error : error_message.join(' ') });
        // setUnauthenticated();
        // throw(error);
      });
  
    };    
}

export function getAccessToken (credentials) {

  // console.log("[authActions.js] loading Access Token...");

  return function(dispatch) {

    dispatch({type: actionTypes.LOAD_ACCESS_TOKEN });

    return authApi.requestAccessToken(credentials).then(response => {

      //
      // Login ok, asetetaan Access token ja expiration-aika millisekunteina.
      //
      //console.log("[authActions.js] Access token received successfully.");

      setAuthorizationToken(response.data.access_token);
      localStorage.setItem('jwtToken', response.data.access_token);
      localStorage.setItem('jwtToken_expiration', response.data.expires_in);
      //console.log("[authActions.js] Access token stored to localStorage.");
      // setAuthenticated();
      dispatch({
          type: actionTypes.LOAD_ACCESS_TOKEN_SUCCESS, 
          token : response.data.access_token, 
          token_expiration : response.data.expires_in 
      });

      return dispatch( push("/") );

      

    }).catch(error => {
      console.log("[authActions.js] Failed to receive Access token.");
      console.log("ERROR: ", error);
      let error_message = 'Kirjautuminen epäonnistui';
      if ( typeof error.response !== 'undefined' && 401 === error.response.status ) {
        error_message = 'Käyttäjätunnus tai salasana väärin.';
      }
      return dispatch( { type: actionTypes.LOAD_ACCESS_TOKEN_ERROR, error : error_message });
      // setUnauthenticated();
      // throw(error);
    });

  };
}

export function getAccessTokenWithEmailToken (credentials) {

  // console.log("[authActions.js] loading Access Token with email token...");

  return function(dispatch) {

    dispatch({type: actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN });

    return authApi.requestAccessToken(credentials).then(response => {

      //
      // Login ok, asetetaan Access token ja expiration-aika millisekunteina.
      //
      // console.log("[authActions.js] Access token received successfully.");

      setAuthorizationToken(response.data.access_token);
      localStorage.setItem('jwtToken', response.data.access_token);
      localStorage.setItem('jwtToken_expiration', response.data.expires_in);
      //console.log("[authActions.js] Access token stored to localStorage.");
      // setAuthenticated();
      return dispatch({
          type: actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_SUCCESS, 
          token : response.data.access_token, 
          token_expiration : response.data.expires_in 
      });

      // BUU, ei ohjata tässä. Kts. myinfosaga
      // return dispatch( push("/") );

      

    }).catch(error => {
      

      let error_message = 'Kirjautuminen epäonnistui';
      let request_new_password_automatically = false;
    
      if ( typeof error.response !== 'undefined' ) {

        if (401 === error.response.status) {
          error_message = 'Käyttäjätunnus tai salasana väärin.';

        } else if (403 === error.response.status && error.response.data.message === 'Expired password' ) {
          error_message = 'Käytit vanhentunutta salasanaa. Katso sähköpostista uusi salasana.'
          request_new_password_automatically = true;
        }
          
      } 

      dispatch( { type: actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_ERROR, error : error_message });
      if (request_new_password_automatically)
        return dispatch(requestLoginEmail({ email : credentials.email }))
      else 
        return dispatch( push("/auth/login") );
    });

  };
}

export function setAccessToken (token) {

  // console.log("[authActions.js] loading Access Token with email token...");

  return function(dispatch) {

      dispatch({ type: actionTypes.SET_ACCESS_TOKEN });

      if (token.access_token) {
        setAuthorizationToken(token.access_token);
        localStorage.setItem('jwtToken', token.access_token);
        localStorage.setItem('jwtToken_expiration', token.expires_in);
        //console.log("[authActions.js] Access token stored to localStorage.");
        // setAuthenticated();
        return dispatch({
            type: actionTypes.SET_ACCESS_TOKEN_SUCCESS, 
            token : token.access_token, 
            token_expiration : token.expires_in 
        });
      } else {
        return dispatch({
          type: actionTypes.SET_ACCESS_TOKEN_ERROR, 
          error: 'Kirjautuminen epäonnistui'
        });
      }
      // BUU, ei ohjata tässä. Kts. myinfosaga
      // return dispatch( push("/") );


  };
}

// TODO tää taitaa olla vnahentunut, joutais pois? T 5.9.2019 
export function getAccessTokenWithHash (hash) {

  //console.log("[authActions.js] getAccessTokenWithHash...");

  return function(dispatch) {

    dispatch({type: actionTypes.LOAD_ACCESS_TOKEN });

    return authApi.requestAccessTokenWithHash(hash).then(response => {

      //
      // Login ok, asetetaan Access token ja expiration-aika millisekunteina.
      //
      //console.log("[authActions.js] Access token received successfully.");

      setAuthorizationToken(response.data.access_token);
      localStorage.setItem('jwtToken', response.data.access_token);
      localStorage.setItem('jwtToken_expiration', response.data.expires_in);
      //console.log("[authActions.js] Access token stored to localStorage.");
      // setAuthenticated();
      dispatch({
          type: actionTypes.LOAD_ACCESS_TOKEN_SUCCESS, 
          token : response.data.access_token, 
          token_expiration : response.data.expires_in 
      });

      return dispatch( push("/") );

      

    }).catch(error => {
      //console.log("[authActions.js] Failed to receive Access token.");
      console.log("ERROR: ", error);
      let error_message = 'Kirjautuminen epäonnistui';
      if ( 401 === error.response.status ) {
        error_message = 'Käyttäjätunnus tai salasana väärin.';
      }
     
      // setUnauthenticated();
      // throw(error);
      return dispatch( { type: actionTypes.LOAD_ACCESS_TOKEN_ERROR, error : error_message });
    });

  };
}


/**
 * App.js tsekkaa aluksi ennen rendausta.
 * Asetetaan localstoragesta token stateen.
 */
export function isAuthenticated() {
  return function (dispatch) {
    let token = localStorage.getItem('jwtToken');
    let token_expiration = parseInt(localStorage.getItem('jwtToken_expiration'));
    let token_has_expired = token_expiration && token_expiration <= Math.floor(Date.now() / 1000);

  

    //console.log("EXPIRE? "+token_expiration + " -- now  "+Math.floor(Date.now() / 1000) );
    if (token && !token_has_expired) {
      // Asetetaan axiosiin
      setAuthorizationToken(token);

      // Tää pitäis ajaa varmaan vasta jos menee toi load my info läpi.
      // console.log('[isAuthenticated] - token set from localstorage. ', token);
      dispatch({
        // Tässä vois laukoo myös AUTH_SUCCESS, mut parempi käyttä ävarmaan vaan tätä yhtä
        // pitäis nimittäin siirtää tämäkin thunkki sagalle. -T 2.11.
        type: actionTypes.STORED_ACCESS_TOKEN_FOUND, 
        token: token,
        token_expiration: token_expiration
      });
      
    } else if (token_has_expired) {
      console.log('[isAuthenticated] - stored token has expired');
      return dispatch({
        type: actionTypes.STORED_ACCESS_TOKEN_EXPIRED,
        token: token,
        token_expiration: token_expiration
      });
    } else {
      console.log('[isAuthenticated] - no stored token');
      return dispatch({
        type: actionTypes.NO_STORED_ACCESS_TOKEN
      });
    }
    
  }
}

 export function inputChanged (form, value, identifier) {
  if (form === 'email_login') {
    return function(dispatch) {
      dispatch({type: actionTypes.EMAIL_LOGIN_FORM_INPUT_CHANGED, payload: {
        value : value,
        identifier : identifier
      } });
    }
  } else if (form === 'login') {
      return function(dispatch) {
        dispatch({type: actionTypes.LOGIN_FORM_INPUT_CHANGED, payload: {
          value : value,
          identifier : identifier
        } });
      }
  } else if (form === 'register') {
    // console.log("AUTH ACTION, REGISTER FORM CHANGED")
    return function(dispatch) {
      dispatch({type: actionTypes.REGISTER_FORM_INPUT_CHANGED, payload: {
        value : value,
        identifier : identifier
      } });
    }
  } else if (form === 'boardauth') {
    // console.log("AUTH ACTION, REGISTER FORM CHANGED")
    return function(dispatch) {
      dispatch({type: actionTypes.BOARD_AUTH_FORM_INPUT_CHANGED, payload: {
        value : value,
        identifier : identifier
      } });
    }
    
  } else if (form === 'shareholderauth') {
    // console.log("AUTH ACTION, REGISTER FORM CHANGED")
    return function(dispatch) {
      dispatch({type: actionTypes.SHAREHOLDER_AUTH_FORM_INPUT_CHANGED, payload: {
        value : value,
        identifier : identifier
      } });
    }
  }
}


export function authAsBoardMember (credentials) {

  // console.log("[authActions.js] loading Access Token...");

  return function(dispatch) {

    dispatch({type: actionTypes.BOARD_MEMBER_AUTH_ASYNC.PENDING });

    return authApi.authAsBoardMember(credentials).then(response => {

      //
      // pin ok,
      dispatch({
          type: actionTypes.BOARD_MEMBER_AUTH_ASYNC.SUCCESS, 
      });
     

    }).catch(error => {

      //console.log("[authActions.js] Failed to auth as board member.");
      //console.log("ERROR: ", error);
      let error_message = 'Tunnistautuminen epäonnistui';
      if ( typeof error.response !== 'undefined' && 401 === error.response.status ) {
        error_message = 'PIN koodi väärin.';
      }
      return dispatch( { type: actionTypes.BOARD_MEMBER_AUTH_ASYNC.ERROR, error : error_message });
      // setUnauthenticated();
      // throw(error);
    });

  };
}


export function authAsShareholder (credentials) {

  // console.log("[authActions.js] loading Access Token...");

  return function(dispatch) {

    dispatch({type: actionTypes.SHAREHOLDER_AUTH_ASYNC.PENDING });

    return authApi.authAsShareholder(credentials).then(response => {

      //
      // pin ok,
      dispatch({
          type: actionTypes.SHAREHOLDER_AUTH_ASYNC.SUCCESS, 
      });
     

    }).catch(error => {

      console.log("[authActions.js] Failed to auth as shareholder.");
      console.log("ERROR: ", error);
      let error_message = 'Tunnistautuminen epäonnistui';
      if ( typeof error.response !== 'undefined' && 401 === error.response.status ) {
        error_message = 'PIN koodi väärin.';
      }
      return dispatch( { type: actionTypes.SHAREHOLDER_AUTH_ASYNC.ERROR, error : error_message });
      // setUnauthenticated();
      // throw(error);
    });

  };
}
