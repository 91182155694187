import * as actionTypes from '../actions/actionTypes.js'
import { formInputChanged } from '../shared/util'
import { toast } from 'react-toastify';

const initialState = {
  isAuthenticated : false,
  emailLoginSentSuccess : false,
  logginWithEmailToken: false,
  error : false,
  isAuthFetching : false,
  redirect : false,
  token : '',
  token_expires : '',
  last_seen : {
    current : '',
    previous : '',
  },

  // TUnnuksella ja salasanalla kirjautuminen
  formIsValid_login : false,
  form_login : {
    email : {
      elementtype : 'text',
      elementconfig : {
        type : 'email',
        placeholder : 'Sähköpostiosoite',
        value : '',
      },
      label : 'Sähköpostiosoite',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },
        
    password : {
      elementtype : 'password',
      elementconfig : {
        type : 'password',
        placeholder : 'Salasana',
        value : '',
      },
      label : 'Salasana',
      validation : {
        required : true,
        maxLength: 32
      },
      valid : false,
      touched : false
    },
    submit : {
      elementtype : 'submit',
      
      elementconfig : {
        type : 'submit',
        value : 'Kirjaudu',
        className: 'btn btn-primary',
      },
      valid : true,
      touched : false
    },      
  },
  // Pelkällä sähköpostilla kirjautuminen
  formIsValid_email_login : false,
  form_email_login : {
    email : {
      elementtype : 'text',
      elementconfig : {
        type : 'email',
        placeholder : 'Sähköpostiosoite',
        value : '',
      },
      label : 'Sähköpostiosoite',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },
    token : {
      elementtype : 'password',
      elementconfig : {
        type : 'password',
        placeholder : 'Salasana',
        value : '',
      },
      isVisible : false, // Ainaki salasana näkyvillä? JOs salasana toimii pitkään niin joo. Jos lyhyenaikaa, ni ei? T 14.2.2020
      label : 'Salasana',
      validation : {
        required : true,
        maxLength: 32
      },
      valid : false,
      touched : false
    },
    submit : {
      elementtype : 'submit',
      elementconfig : {
        type : 'submit',
        value : 'Kirjaudu',
        className: 'btn btn-primary',
      },
      valid : true,
      touched : false
    },      
  },  
  
  formIsValid_board_auth : false,
  form_board_auth : {
    pin : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : 'PIN koodi',
        value : '',
      },
      label : 'PIN koodi',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },
    
    submit : {
      elementtype : 'submit',
      elementconfig : {
        type : 'submit',
        value : 'Tunnistaudu',
        className: 'btn btn-primary',
      },
      valid : true,
      touched : false
    },      
  },

  formIsValid_shareholder_auth : false,
  form_shareholder_auth : {
    pin : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : 'PIN koodi',
        value : '',
      },
      label : 'PIN koodi',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },
    
    submit : {
      elementtype : 'submit',
      elementconfig : {
        type : 'submit',
        value : 'Tunnistaudu',
        className: 'btn btn-primary',
      },
      valid : true,
      touched : false
    },      
  },  

  formIsValid_register : false,
  form_register : {
    firstname : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : 'Etunimi *',
        value : '',
      },
      label : 'Etunimi *',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },  
    lastname : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : 'Sukunimi *',
        value : '',
      },
      label : 'Sukunimi *',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },   
    email : {
      elementtype : 'text',
      elementconfig : {
        type : 'email',
        placeholder : 'Sähköpostiosoite *',
        value : '',
      },
      label : 'Sähköpostiosoite *',
      validation : {
        required : true
      },
      valid : false,
      touched : false
    },
    phone : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        pattern : '[0-9]*',
        placeholder : '0401234567',
        value : '',
      },
      label : 'Puhelin',
      validation : {
        required : false
      },
      valid : true,
      touched : false
    }    
       
  }
}

const authReducer = (state = initialState, action) => {

  switch(action.type) {
    
    case actionTypes.LOGIN_FORM_INPUT_CHANGED :
      const form_login = formInputChanged(state.form_login, action);
      return {
        ...state,
        form_login : form_login.form,
        formIsValid_login : form_login.formIsValid
      }
    case actionTypes.EMAIL_LOGIN_FORM_INPUT_CHANGED :
      const form_email_login = formInputChanged(state.form_email_login, action);
      return {
        ...state,
        form_email_login : form_email_login.form,
        formIsValid_email_login : form_email_login.formIsValid
      }    
    case actionTypes.BOARD_AUTH_FORM_INPUT_CHANGED :
      const form_board_auth = formInputChanged(state.form_board_auth, action);
      return {
        ...state,
        form_board_auth : form_board_auth.form,
        formIsValid_board_auth : form_board_auth.formIsValid
      }  
    case actionTypes.SHAREHOLDER_AUTH_FORM_INPUT_CHANGED :
        const form_shareholder_auth = formInputChanged(state.form_shareholder_auth, action);
        return {
          ...state,
          form_shareholder_auth : form_shareholder_auth.form,
          formIsValid_shareholder_auth : form_shareholder_auth.formIsValid
        }                    

    case actionTypes.AUTH_SUCCESS :
    
      return {
        ...state,
        isAuthenticated : true,
        isAuthFetching : false,
        error : false, 
        token : action.token,
        token_expiration : action.token_expiration
      }

    case actionTypes.AUTH_FAILED :
      return {
        ...state,
        isAuthenticated : false,
        isAuthFetching : false,
        error : false, // 'Kirjautuminen epäonnistui',       
        token : '',
        token_expiration : ''
      }
      case actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.PENDING :
        return {
          ...state,
          isAuthFetching : true,
        }
  
      case actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.SUCCESS :
  
        return {
          ...state,
          isAuthenticated : true,
          error: false,
          isAuthFetching : false,
          // Näitä just tsekattiin, ja ol oikkein.
          // token : action.token,
          // token_expiration : action.token_expiration            
        }
  
      case actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.ERROR :
  
        return {
          ...state,
          isAuthenticated : false,
          error: action.error,
          isAuthFetching : false,
          token : '',
          token_expiration : ''            
        }

    case actionTypes.STORED_ACCESS_TOKEN_EXPIRED :
      return {
        ...state,
        //isAuthenticated : false, // ei vaihdeta tän tilaa vielä
        isAuthFetching : true,
        error : false,
        token : action.token,
        token_expiration : action.token_expiration
      }    
     case actionTypes.STORED_ACCESS_TOKEN_FOUND :
        return {
          ...state,
          // ei vaihdeta tän tilaa vielä
          //isAuthenticated : false, 
          isAuthFetching : false,
          error : false,
          token : action.token,
          token_expiration : action.token_expiration
      }            
    case actionTypes.NO_STORED_ACCESS_TOKEN :
    
        return {
          ...state,
          isAuthenticated : false,
          isAuthFetching : false,
          error : false, 
          token : '',
          token_expiration : ''
        }      
    case actionTypes.LOGOUT :

      return {
        ...state,
        isAuthFetching : false,
        isAuthenticated : false,
        error : false,       
        token : '',
        token_expiration : ''
      }

    case actionTypes.MANUAL_LOGOUT :
      // Näytetää toast kun asiakas on klikannu logouttii.
      // Teemu 4.10.2019
      toast.success("Uloskirjautuminen onnistui.");
      return {
        ...state
      }
    
              
    case actionTypes.REGISTER_FORM_INPUT_CHANGED :

      const form_register = formInputChanged(state.form_register, action);

      return {
        ...state,
        form_register : form_register.form,
        formIsValid_register : form_register.formIsValid
      }   

    case actionTypes.REGISTER_USER :
      return {
        ...state,
        isAuthFetching : true
      }

    case actionTypes.REGISTER_USER_SUCCESS :

      toast.success("Rekisteröityminen onnistui. Sait salasanan sähköpostiisi!");
      return {
        ...state,
        error: false,
        isAuthFetching : false,
        form_register: initialState.form_register
      }

    case actionTypes.REGISTER_USER_ERROR :
      if (action.error) {
        toast.error(action.error);
      }
      
      return {
        ...state,
        error: action.error,
        isAuthFetching : false,
      }

    case actionTypes.OAUTH_LOGIN_POPUP.PENDING : 
        return {
          ...state,
          isAuthFetching : true,
        }
    case actionTypes.OAUTH_LOGIN_POPUP.SUCCESS : 
        return {
          ...state,
          isAuthFetching : false,
        }        
    case actionTypes.OAUTH_LOGIN_POPUP.ERROR : 
        if (action.error) {
          toast.info(action.error);
        }
        return {
          ...state,
          isAuthFetching : false,
        }

      case actionTypes.OAUTH_LOGOUT_POPUP.PENDING : 
        return {
          ...state,
          isAuthFetching : true,
        }
    case actionTypes.OAUTH_LOGOUT_POPUP.SUCCESS : 
        return {
          ...state,
          isAuthFetching : false,
        }        
    case actionTypes.OAUTH_LOGOUT_POPUP.ERROR : 
        if (action.error) {
          toast.error(action.error);
        }
        return {
          ...state,
          isAuthFetching : false,
        }        

    case actionTypes.LOAD_ACCESS_TOKEN :
      return {
        ...state,
        isAuthFetching : true,
      }

    case actionTypes.LOAD_ACCESS_TOKEN_SUCCESS :

      return {
        ...state,
        isAuthenticated : true,
        error: false,
        isAuthFetching : false,
        token : action.token,
        token_expiration : action.token_expiration,
        form_login: initialState.form_login
      }

    case actionTypes.LOAD_ACCESS_TOKEN_ERROR :
      if (action.error) {
        toast.error(action.error);
      }
      return {
        ...state,
        isAuthenticated: false,
        error: action.error,
        isAuthFetching : false,
        token : '',
        token_expiration : '',
        // TODO : kun kirjautumienn ei onnistu,
        // mitenpä alustat passwordikentän tyhjäksi?
        // form { password : '' }
      }


      case actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN :
        return {
          ...state,
          isAuthFetching : true,
          logginWithEmailToken: true,
        }
  
      case actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_SUCCESS :
  
        return {
          ...state,
          isAuthenticated : true,
          error: false,
          isAuthFetching : false,
          logginWithEmailToken: false,
          token : action.token,
          token_expiration : action.token_expiration,
          form_email_login: initialState.form_email_login,
          
          
        }
  
      case actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_ERROR :
        if (action.error) {
          toast.error(action.error);
        }
        // Pannaa salasanakenttä piiloon? ei panna
        //let form_email_login_token_field = { ...initialState.form_email_login.token, isVisible : false };
        // Tyhjätää kuiteki salasanakenttä
        // Ai saakeli mikä homma, mitehä tämmöset pitäis theä? 
        let form_email_login_token_field =  { ...initialState.form_email_login.token, isVisible : true };
        form_email_login_token_field.elementconfig.value = '';
        
        return {
          ...state,
          isAuthenticated: false,
          error: action.error,
          isAuthFetching : false,
          logginWithEmailToken: false,
          token : '',
          token_expiration : '',
          form_email_login: { ...initialState.form_email_login, token : form_email_login_token_field },
         }      

    case actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.PENDING :
      return {
        ...state,
        isAuthFetching : true,
      }

    case actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.SUCCESS :

      return {
        ...state,
        isAuthenticated : true,
        error: false,
        isAuthFetching : false,
        token : action.token,
        token_expiration : action.token_expiration            
      }

    case actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.ERROR :
     
      // Local storagesta mäkeen
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('jwtToken_expiration');

      return {
        ...state,
        isAuthenticated : false,
        error: action.error,
        isAuthFetching : false,
        token : '',
        token_expiration : ''            
      }
      
      case actionTypes.NO_REFRESH_TOKEN :

        return {
          ...state,
          isAuthenticated : false,
          error: false,
          isAuthFetching : false,
          token : '',
          token_expiration : ''            
        }


      // Oauth kirjautumisen jälkeen asettaa access tokenin 3.9.2020 -T
      case actionTypes.SET_ACCESS_TOKEN :
        return {
          ...state,
          isAuthFetching : true,
        }
  
      case actionTypes.SET_ACCESS_TOKEN_SUCCESS :
  
        return {
          ...state,
          isAuthenticated : true,
          error: false,
          isAuthFetching : false,
          token : action.token,
          token_expiration : action.token_expiration,
        }
  
      case actionTypes.SET_ACCESS_TOKEN_ERROR :
        if (action.error) {
          toast.error(action.error);
        }
        return {
          ...state,
          isAuthenticated: false,
          error: action.error,
          isAuthFetching : false,
          token : '',
          token_expiration : '',
          // TODO : kun kirjautumienn ei onnistu,
          // mitenpä alustat passwordikentän tyhjäksi?
          // form { password : '' }
        }      

    case actionTypes.REQUEST_LOGIN_EMAIL_ASYNC.PENDING :
        return {
          ...state,
          isAuthFetching : true,
          emailLoginSentSuccess : false,

        }
  
    case actionTypes.REQUEST_LOGIN_EMAIL_ASYNC.SUCCESS :
  
      // Pannaa salasanakenttä näkyville
      let form_email_login_password_visible = { ...initialState.form_email_login.token, isVisible : true };
        return {
          ...state,
          error: false,
          isAuthFetching : false,
          form_email_login: { ...initialState.form_email_login, token : form_email_login_password_visible },
          emailLoginSentSuccess : true,
        }
  
    case actionTypes.REQUEST_LOGIN_EMAIL_ASYNC.ERROR :
        if (action.error) {
          toast.error(action.error);
        }
        return {
          ...state,
          error: action.error,
          isAuthFetching : false,
          emailLoginSentSuccess : false
        }

    case actionTypes.BOARD_MEMBER_AUTH_ASYNC.PENDING :
        return {
          ...state,
          isAuthFetching : true,
        }
    
    case actionTypes.BOARD_MEMBER_AUTH_ASYNC.SUCCESS :
      // TODO: tän toastin sijaan pitäis olla DOMNotification ja siitä linkki sitte eteepäi hommiin pommiin
      // Teemu 26.9.2019
        toast.success("Olet tunnistautunut hallituksen jäseneksi!");
        return {
          ...state,
          error: false,
          isAuthFetching : false,
          form_board_auth: { ...initialState.form_board_auth },
        }
    
    case actionTypes.BOARD_MEMBER_AUTH_ASYNC.ERROR :
        if (action.error) {
          toast.error(action.error);
        }
        return {
          ...state,
          error: action.error,
          isAuthFetching : false,
        }

    case actionTypes.SHAREHOLDER_AUTH_ASYNC.PENDING :
        return {
          ...state,
          isAuthFetching : true,
        }
      
    case actionTypes.SHAREHOLDER_AUTH_ASYNC.SUCCESS :
        // TODO: tän toastin sijaan pitäis olla DOMNotification ja siitä linkki sitte eteepäi hommiin pommiin
      // Teemu 26.9.2019
      toast.success("Olet tunnistautunut osakkaaksi!");
        return {
          ...state,
          error: false,
          isAuthFetching : false,
          form_shareholder_auth: { ...initialState.form_shareholder_auth },
        }
      
    case actionTypes.SHAREHOLDER_AUTH_ASYNC.ERROR :
        if (action.error) {
          toast.error(action.error);
        }
        return {
          ...state,
          error: action.error,
          isAuthFetching : false,
        }          



    default:
      return state;
  }

};

export default authReducer;
