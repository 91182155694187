import React from 'react';

const ProgressBar = (props) => {

  let inlineStyle = {
      width: props.progress + '%'
  }
  
  return (
  <div className="progressBar">
    <div className="progressed" style={inlineStyle}></div>
  </div>
  )
}

export default ProgressBar;
