/**
* Auth sagas
* Nyt vaan säädetään user reference, jos tultiin taloyhtiön linkillä appiin.
* TODO: varmaan sais noi login ja register ja muutkin tätä kautta niin ei 
* tarvis niitä thunkilla.
*/
import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import * as actionTypes from '../actions/actionTypes'
import { push } from 'connected-react-router'
import {manualLogout, logout, storedAccessTokenAuthActions, refreshAccessTokenActions} from '../actions/authActions';
import AuthApi from '../api/authApi'
import { setAuthorizationToken } from '../shared/util';

const getCurrentUserID = (state) => state.myinfo.currentUser.id;
const getMyID = (state) => state.myinfo.myinfo.id;
const getCurrentToken = (state) => state.auth.token;

const refreshAccessTokenCall = (old_token) => AuthApi.refreshAccessTokenApi(old_token);
const checkAccessTokenCall = (token) => AuthApi.checkAccessTokenApi(token);


/**
 * Jos tultiin appiin sisään taloyhtiön urlilla 
 * oma/asdasd
 * 
 * Kirjautuneena:
 * Liitetään taloyhtiö käyttäjään, otataa taloyhtiö valituksi
 * Ei kirjautuneena:
 * Ohjataan kirjautumiseen, tai rekisteröitymiseen,
 * josta kytkeytyy taloyhtiöön.
 * 
 * 
 */
function* redirectWithUserReference(action) {

  try {
    if (action.error) {
       //console.log("AUTH SAGA REF ERROR REDIRECT");
        yield put ( push('/') );
    } else {

      const currentUserID = yield select(getCurrentUserID);
      
      if (!currentUserID) {
        yield put ( push('/auth/register') );
      } else {
        yield put ( push('/') );
      }
    }
  } catch (e) {
    console.log(e)
    yield put( push('/') );
  }

}

/**
 * Autentikoinnin epäonnistuessa, ohjaan kirjautumiseen.
 * 
 */
function* redirectToLogin() {
  

    yield put( logout() )
    yield put( push('/auth/login') );
}

/**
 * Autentikoinnin epäonnistuessa, ohjaan etusivulle.
 * 
 */
function* redirectToHome() {
  
  yield put( push('/') );
}

/**
 * Oauth hommissa uloskirjaaminen identity providerista popup ikkunan kautta,
 * sen jälkeen täytyy vielä uloskirjata tästä appista normaalisti API:sta.
 * 30.9.2020 -T
 */
function* doLogout() {
  yield put ( manualLogout() )
}


/*
function *onUnauthorizedRequest(response) {
  console.log("Unauthorized requst, AuthSaga", response);

  refreshAccessToken
}
*/

function *refreshAccessToken (action) {

  console.log("Trying refreshAccessToken!");

  let token = typeof action.token != 'undefined' ? action.token : yield select(getCurrentToken);

    // Koitetaan hakea uus token, jos vanha oli olemassa.
    if (!token) {
       yield put( refreshAccessTokenActions.cancel() );

    } else {
      
      try {

        yield put( refreshAccessTokenActions.pending() );
        const response = yield call(refreshAccessTokenCall, token);
    
        if (response) {
          
          //console.log("[authActions.js] Access token received successfully.");
          setAuthorizationToken(response.data.access_token);
          localStorage.setItem('jwtToken', response.data.access_token);
          localStorage.setItem('jwtToken_expiration', response.data.expires_in);

          yield put( refreshAccessTokenActions.success(response.data) );
        } else {
          throw String("Refresh access token failed.");
        }

        
      } catch (e) {
        //console.log("refresh access token exception", e);
        yield put( refreshAccessTokenActions.error(e))
      }
    }

}

/**
 * Localstoragesta löytyvän tokenin testaus.
 * Tsekataan periaatteessa aina. Eroa on, tultiinko appiin pitkästäaikaa
 * vai ollaanks tässä kokoajan pyöritty. 
 * Jos ollaan kokoajan pyöritty, on todnäk kaikki ok.
 * Jos ei löydy esim omia tietoja, ni silloinhan tää pitää tsekata ja noin.
 * 
 * @param {*} action 
 */
function *checkStoredAccessToken (action) {


  // Jos statessa on omat tiedot, ei mennä ny rajapinnalle.
  // Rajapinnalla käydään kuitenkin melkokohta, kun käyttäjä koskettaa jotain.
  let myID = yield select(getMyID);
  if (myID) {
   
    // console.log("stored token ok", myID);
  
  } else {

    yield put( storedAccessTokenAuthActions.pending() );
    
    try {
      let token = typeof action.token != 'undefined' ? action.token : yield select(getCurrentToken);



      const response = yield call(checkAccessTokenCall, token);
  
      if (response) {
        yield put( storedAccessTokenAuthActions.success(response.data) );
      } else {
        throw String("Access token wasn't ok.");
      }

      
    } catch (e) {
      //console.log("refresh access token exception", e);
      yield put( storedAccessTokenAuthActions.error(e))
    }
  }
}


export default function* () {
  yield all([
    takeLatest( actionTypes.GET_USER_REFERENCE_ASYNC.SUCCESS, redirectWithUserReference ),
    takeLatest( actionTypes.GET_USER_REFERENCE_ASYNC.ERROR, redirectWithUserReference),
    takeLatest( actionTypes.AUTH_FAILED, redirectToLogin),
    takeLatest( actionTypes.OAUTH_LOGIN_POPUP.ERROR, redirectToHome),
    // Jos authista uloskirjaaminen epäonnistuu, kirjataan kuitenkin appistakii ulos
    takeLatest( actionTypes.OAUTH_LOGOUT_POPUP.ERROR, doLogout),
    takeLatest( actionTypes.OAUTH_LOGOUT_POPUP.SUCCESS, doLogout),

    // Axios interceptori laukoo tän, jos palautuu 401 mistä tahansa rajapintapyynnöstä
    takeLatest( actionTypes.UNAUTHORIZED_REQUEST, refreshAccessToken),
    takeLatest( actionTypes.STORED_ACCESS_TOKEN_EXPIRED, refreshAccessToken),
    takeLatest(actionTypes.STORED_ACCESS_TOKEN_FOUND, checkStoredAccessToken),
    // Jouvutaa välillä tsekkaamaan muutenkin, että mieluummin ajoissa kun myöhää
    // freesaus.
    takeLatest( actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.ERROR, redirectToHome)
  ])
}
