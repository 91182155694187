import * as actionTypes from './actionTypes';
import contactFormApi from '../api/contactFormApi';


export function setContactFormInitialValues (values) {
  return function(dispatch) {
    dispatch({type: actionTypes.CONTACT_FORM_SET_INITIAL_VALUES, payload: values });
  }
}

export function inputChanged (value, identifier) {
  return function(dispatch) {
    dispatch({type: actionTypes.CONTACT_FORM_INPUT_CHANGED, payload: {
      value : value,
      identifier : identifier
    } });
  }
}

//
//  CONTACT FROM LÄHETYS
//
export function sendContactForm(formData) {

  // make async call to api, handle promise, dispatch action when promise is resolved
  return function(dispatch) {

    dispatch({type: actionTypes.CONTACT_FORM_SEND});
    //dispatch({type: actionTypes.CONTACT_FORM_SENT_SUCCESS});

    return contactFormApi.sendContactFormApi(formData).then(payload => {

      return dispatch({type: actionTypes.CONTACT_FORM_SENT_SUCCESS, payload});
    }).catch(error => {
      console.log("ERROR: ", error);
      return dispatch({type: actionTypes.CONTACT_FORM_SENT_ERROR, error});
    });
  };
}

// Resetoi Formi
export function resetContactForm () {
  return function (dispatch) {
    dispatch({type: actionTypes.CONTACT_FORM_RESET});
  }
}
