import React from 'react';

const Attachment = (props) => {

    let mimetypeClass = ClassFromMimetype(props.mimetype);
    let className = "attachment " + mimetypeClass;
    
    if (props.url && props.elem === 'a') {
        let clickURL = props.url + '?token=' + localStorage.getItem("jwtToken");
        return (
        <a href={clickURL} className={className} target={props.target} onClick={props.onClick}>
            <svg className="icon-dowload" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zm-5.55-8h-2.9v3H8l4 4 4-4h-2.55z"/></svg>
            <span className="filetype-icon">{ FiletypeSVG() }</span>
            <span className="caption">Lataa {props.name}</span>
        </a>
        );
    } else {
        className = className + " disabled";
        return (
            <p className={className}>
                <svg  className="icon-dowload icon-dowload-disabled" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zm-5.55-8h-2.9v3H8l4 4 4-4h-2.55z"/></svg>
                <span className="filetype-icon">{ FiletypeSVG() }</span>
                <span className="caption">{props.name}</span>
            </p>
            );
    }

}

const ClassFromMimetype = (mimetype) => {

    if (!mimetype)
        return '';

    if (mimetype.indexOf('image') === 0)
        return 'image';

    if (mimetype.indexOf('application') === 0) {
        let parts = mimetype.split('/');
        if (parts.length === 2)
            return parts[1].replace(/[\W_]+/g,"-");
    }

    return '';
}

// Ikonit peräisin Material design kirjastosta 
// PDF tyyliin https://material.io/resources/icons/?search=pdf&icon=picture_as_pdf&style=baseline
// mustalla taustalla, jne. 
// Avaa ikoni tekstieditoriin, sieltä suoraan koodi.
// Teemu 19.8.2019
// ps. material ikoneissa eijo kyl eri tiedostotyyppei sen kummemmin... PDF mäihällä... palautetaan klemmari
const FiletypeSVG = (mimetype) => {



    return "";
    /*
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg> 
    ); 
    */
    
    /*
    let mimeclass = ClassFromMimetype(mimetype);

    if (mimeclass == 'pdf')
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"/></svg>
        )

    if (mimeclass == 'image') 
        return(
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/></svg>
        ) */
}

export default Attachment;
