import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class Page_Info extends Component {

  registerButton() {
    if (this.props.isAuthenticated)
      return '';

    return (
      <Link to="/auth/register/" className="no-decoration centered">
          <button type="button" className="btn btn-primary">
                Rekisteröidy
          </button>
      </Link> 
    );
  }

  loginButton() {

    if (this.props.isAuthenticated) {
      return (
      <Link to="/auth/logout/" className="no-decoration">
        <button type="button" className="btn btn-primary">
            Kirjaudu ulos
        </button>
      </Link>);
    }

    return (
    <Link to="/auth/login/" className="no-decoration">
        <button type="button" className="btn btn-primary">
            Kirjaudu
        </button>
    </Link>);
  }

  render() {

   

    return (
        <div>
          <h1 className="page-title">Info</h1>

          <article className="card">
            <h2 className="article-title">Rekisteröityminen</h2>

            <p>Kontuappi (ent. ISTOappi) on henkilökohtainen. Appiin rekisteröidytään omalla nimellä, sekä sähköpostiosoitteella.</p>
            <p>Rekisteröitymisen yhteydessä liitytään oman taloyhtiöön antamalla oman taloyhtiön tunniste. Vaihtoehtoisesti voit rekisteröityä taloyhtiösi omalla rekisteröitymislinkillä.</p>

            {this.registerButton()}
             
          </article> 

          <article className="card">
            <h2 className="article-title">Sisäänkirjautuminen</h2>
            <p>Sisäänkirjautumiseen tarvitaan salasana. Salasana lähetetään sinulle sähköpostitse. Sovellus pysyy sisäänkirjautuneena, kunnes kirjaudut ulos.</p>

            {this.loginButton()}
          </article>

          <article className="card">
            <h2 className="article-title">Omat tiedot</h2>
            <p>Omia tietoja voi tarkastella asetuksista.</p>
            <p>Asetuksista löydät myös keinot poistaa oman taloyhtiön apista, jos olet esimerkiksi muuttamassa.</p>
            <p>Voit myös lisätä appiin lisää taloyhtiöitä ja tunnistautua taloyhtiön hallituksen jäseneksi, tai osakkaaksi.</p>
            <p>Osakkaaksi ja hallituksen jäseneksi tunnistautuneille näkyy lisäksi heille kohdistettua sisältöä.</p>

            <Link to="/settings/" className="no-decoration">
               <button type="button" className="btn btn-primary">
                    Asetukset
               </button>
            </Link>
          </article>          
          
          </div>

    )
  }


}

const mapStateToProps = (state) => {

  return {
    isAuthenticated : state.auth.isAuthenticated,
  }
};

export default connect(mapStateToProps)(Page_Info);