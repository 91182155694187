import axios from 'axios';
import {APIpath, getUserID } from '../shared/util';

class ContactFormApi {

  static sendContactFormApi(formData) {

    return axios.post(`${APIpath}users/${getUserID()}/messages`,
      formData)
    .then(response => response)
    .catch(error => {
      throw(error)
    });
  }

}
export default ContactFormApi;
