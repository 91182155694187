/**
* Board archive sagas
*/
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { actions } from '../actions/boardArchiveActions'
import * as actionTypes from '../actions/actionTypes'
import boardArchiveApi from  '../api/boardArchiveApi'
import { considerAsOldContent, getSeen } from '../shared/util'


const fetchArticles = (id) => boardArchiveApi.getArticles(id);
const getCurrentUser = (state) => state.myinfo.currentUser.id ? state.myinfo.currentUser : false;
// ...


function* getBoardArchive() {
  
  try {
    
    // Select hakee statesta 
    const currentUser = yield select(getCurrentUser);
    if (currentUser) {

      if (!currentUser.board_member) {
        throw String("Not board member");
      } else {
        const response = yield call(fetchArticles, currentUser.id)
        // TODO pitäis varmaan tuikkaa ens localstoragesta, jos on siellä. 
        // JA täs myö varmaan haluttasiin asettaa artikkelien status
        let seen = getSeen('board_document');
        let articles = response.data.data.map( (article) => {
          try {
            if (considerAsOldContent(article.created_at.date) || seen.find(seen_id => seen_id===article.id))
              article.status = 'seen';
            else
              article.status = 'new';
          } catch(e) {
            // Nuttin
          }
          return article;
        });
  
        yield put(actions.success(articles))
      }
    } else {
      throw String("No current user set!");
    }

  } catch (e) {
    console.log("getBoardArchive error", e);
    yield put(actions.error(e))
  }
}

function* refreshBoardArchive( action ) {
  //console.log("Refresh board saga", action);
  yield put( { type : actionTypes.LOAD_BOARD_ARCHIVE_ASYNC.PENDING } );
}


export default function* () {
  yield all([
    takeLatest(actionTypes.LOAD_BOARD_ARCHIVE_ASYNC.PENDING, getBoardArchive),
    takeLatest(actionTypes.CURRENT_USER_CHANGED, refreshBoardArchive),
    //takeLatest(actionTypes.SET_CURRENT_USER.SUCCESS, refreshBoardArchive),
    takeLatest(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.SUCCESS, refreshBoardArchive),

    takeLatest(actionTypes.APP_CONNECTION_ONLINE, refreshBoardArchive),
    takeLatest(actionTypes.BOARD_MEMBER_AUTH_ASYNC.SUCCESS, refreshBoardArchive)
  ])
}