import React from 'react';
const Preloader = (props) => {
    let addClass = props.inline ? ' inline-loader' : 
                   props.absolute ? ' out-of-way' : 
                   '';
    return (
        <div className={"loader" + addClass}>
          <svg viewBox="0 0 32 32" width="32" height="32">
            <circle id="spinner" cx="16" cy="16" r="14" fill="none"></circle>
          </svg>
        </div>
    );
}

export default Preloader;
