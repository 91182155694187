import React from 'react';

class FormAttachment extends React.Component {

    // let clickURL = props.url + '?token=' + localStorage.getItem("jwtToken");
    render () {
      return (
        <div className="form-attachment">

          {this.props.thumbnail &&

            <img src={this.props.thumbnail} alt="" />

          }

          <button type="btn btn-primary" onClick={this.props.handleClick}>Poista liite</button>

        </div>
      )
    }

}

export default FormAttachment;
