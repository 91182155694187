import * as actionTypes from '../actions/actionTypes.js'
import { createReducer } from '../store/Utilities'

const initialState = {
  articles : [],
  isLoading: false,
  error: null
}

export default createReducer(initialState, {
  [actionTypes.LOGOUT_BULLETIN](state) {
    return {
      ...state,
      articles: [],
      error : false
    }
  },  
  [actionTypes.LOAD_BULLETIN_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true,
      error : false
    }
  },
  [actionTypes.LOAD_BULLETIN_ASYNC.SUCCESS](state, action) {
//    console.log(action);
    return {
      ...state,
      articles: action.articles,
      isLoading: false,
      error : false
    }
  },
  [actionTypes.LOAD_BULLETIN_ASYNC.ERROR](state) {
    return {
      ...state,
      articles: [],
      isLoading: false,
      error : true
    }
  }
})
/*
const bulletinReducer = (state = initialState, action) => {

  switch(action.type) {

    case actionTypes.LOAD_BULLETIN_FETCH:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case actionTypes.LOAD_BULLETIN_SUCCESS:
      return {
        ...state,
        articles: action.payload.data.data,
        isLoading: false,
        error: false
      }
      case actionTypes.LOAD_BULLETIN_ERROR:
        return {
          ...state,
          isLoading: false,
          error: action.error
        }
    default:
      return state;
  }

};

export default bulletinReducer;
*/