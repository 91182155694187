/**
* App sagas
*/
import { all,  call, takeLatest } from 'redux-saga/effects'
import * as actionTypes from '../actions/actionTypes'
import { setSeen } from '../shared/util'

const setSeenArticle = (type, id) => { setSeen(type, id) }

/**
 * Merkataan artikkeli nähdyksi
 * 
 */
function* markSeenArticle( action ) {
    if (action.article && action.article.id) {
        yield call(setSeenArticle, action.article_type, action.article.id)
    }
    // yield put( logout() )
    // yield put( push('/auth/login') );
}

/**
 * Pietää kirjaa, kun sisäänkirjaaminen onnistuu. 
 * Jos tämmösen jälkeen joku muu toimito ei onnistu (eli palvelin ei vastaa mitä pitäisi),
 * voidaan tulkita että palvelin yhteys toimii, mutta siellä on jotain vikaa.
 * 
 * 
 */
function record_successfull_login( action ) {
  // TODO tallenna kellonaika 
}

export default function* () {
  yield all([
    takeLatest( actionTypes.APP_ARTICLE_CLICKED, markSeenArticle ),
    takeLatest( actionTypes.LOAD_ACCESS_TOKEN_SUCCESS, record_successfull_login )
  ])
}
