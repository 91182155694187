import React, { Component } from 'react';
import Layout from './Layout';
import { Route,
         Switch, withRouter } from 'react-router-dom';

import PrivateRoute from './Privateroute';
import '../css/bootstrap.scss';
import "react-toastify/dist/ReactToastify.css";
import '../css/App.css';
import Page_Home from './home/Page_Home';
import Page_News from './news/Page_News';
import Page_Bulletin from './bulletin/Page_Bulletin';
import Page_Settings from './settings/Page_Settings';
import Page_Documents from './documents/Page_Documents';
import Page_Shareholder_Archive from './shareholders/Page_Shareholder_Archive';
import Page_Board_Archive from './board/Page_Board_Archive';
import MaestroNG_Iframe from './board/MaestroNG_Iframe';
import Page_Contact from './contact/Page_Contact';
import Page_Maintenance_Request from './maintenance_request/Page_Maintenance_Request';
import Page_Info from './info/Page_Info';
import Page_Privacy_Policy from './info/Page_Privacy_Policy';
import Page_Add_User from './users/Page_Add_User';
import Page_Switch_User from './users/Page_Switch_User';
import RegisterView from './auth/Register';
import Page_Asumisentietopankki from './asumisentietopankki/Page_Asumisentietopankki';
import BoardMemberAuthorizationView from './auth/BoardMemberAuthorizationView';
import ShareholderAuthorizationView from './auth/ShareholderAuthorizationView';
//import LoginView from './auth/Login';
import LoginWithEmailView from './auth/LoginWithEmail';
import LoginOauthView from './auth/LoginOauth';

import LogoutOauthView from './auth/LogoutOauth';
import Logout from './auth/Logout';
import IndividualToUser from './auth/IndividualToUser';
import Page_404 from './Page_404';
import { connect } from 'react-redux';
import { isAuthenticated } from '../actions/authActions';
import LoginOauth from './auth/LoginOauth';

import { MaestrongOstolaskutURI } from '../shared/util';

class App extends Component {
  UNSAFE_componentWillMount() {

    // Lauotaan navigoinnista actionia
    this.unlisten = this.props.history.listen((location, action) => {
      // console.log('You changed the page to: ', location.pathname);
      this.props.dispatch(isAuthenticated());
      // console.log("navigointi", location, action);
      // Ruutu ylös ku sivu vaihtuu
      window.scrollTo(0, 0);
    });
  }
  componentWillUnmount() {
      this.unlisten();
  }

  render() {

        // TODO : /oma -polku pitäis olla authedin takana, mutta privateroute tsekkaa
        // myös currentuserin mikä ei toimi, pitäis olla eri komponentti.

        // <PrivateRoute authed={this.props.isAuthenticated} path='/' exact component={Page_Home} />
        //<PrivateRoute authed={this.props.isAuthenticated} path='/maestrong' component={MaestroNG_Iframe} />
        let routes = (
          <Switch>
            <Route path='/' exact component={Page_Home} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/documents' component={Page_Documents} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/bulletin' component={Page_Bulletin} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/shareholder-archive' component={Page_Shareholder_Archive} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/board-archive' component={Page_Board_Archive} />
            
            <PrivateRoute authed={this.props.isAuthenticated} path='/maestrong' component={() => { window.location = MaestrongOstolaskutURI; return null;} }/>

            <PrivateRoute authed={this.props.isAuthenticated} path='/news' component={Page_News} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/maintenance_request' component={Page_Maintenance_Request} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/contact' component={Page_Contact} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/auth/logout' component={LogoutOauthView} />
            <Route path='/auth/client-logout' component={Logout} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/as-board-member' component={BoardMemberAuthorizationView} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/as-shareholder' component={ShareholderAuthorizationView} />
            <PrivateRoute authed={this.props.isAuthenticated} path='/asumisentietopankki' component={Page_Asumisentietopankki} />
            <Route path="/oma/:hash" component={IndividualToUser}/>
            <Route path="/oma" component={Page_Add_User}/>
            <PrivateRoute authed={this.props.isAuthenticated} path="/users/" component={Page_Switch_User}/>
            <Route path="/auth/register" component={RegisterView}/>
            <Route path="/auth/login" component={LoginOauthView}/>
            <Route path="/auth/:email/:token" component={LoginWithEmailView}/>
            <Route path="/info" component={Page_Info}/>
            <Route path="/privacy_policy" component={Page_Privacy_Policy}/>
            <Route path='/settings' authed={this.props.isAuthenticated} component={Page_Settings}  />
            <Route component={Page_404} />
          </Switch>
          )

        return (

          <Layout offline={this.props.offline} location={this.props.location}>
            {routes}
          </Layout>

        )

  }

}

const mapStateToProps = (state) => {
  return {
    isAuthenticated : state.auth.isAuthenticated,
    users : state.myinfo.myinfo.users,
    offline : state.app.online ? false : true
  }
};

// withRouter tarvitaan connected-react-router
// muuten ei navigaatio toimi
export default withRouter(connect(mapStateToProps)(App));
