import axios from 'axios';
import {APIpath} from '../shared/util';

class InfoApi {


  static getHousingReference(hash) {
  
    let info_endpoint = APIpath + 'info/housing/' + hash;
    return axios.get(info_endpoint)
    .then(response => response.data )
    .catch(error => {
      throw (error);
    });
  }

}
export default InfoApi;
