import axios from 'axios';
import {APIpath,istonettiOauthLogoutURI,setAuthorizationToken} from '../shared/util';

class AuthApi {


  static registerUser(data) {
  
    let register_endpoint = APIpath + 'auth/register';
    
    return axios.post(register_endpoint, data)
    .then(response => response)
    .catch(error => {
      return Promise.reject(error);
      // throw (error);
    });
  }

  static addIndividualUser(data) {
  
    let connect_endpoint = APIpath + 'auth/connect-user/' + data.hash_id;
    return axios.post(connect_endpoint)
    .then(response => response)
    .catch(error => {
      return Promise.reject(error);
      // throw (error);
    });
  }

  static removeIndividualUser(data) {
  
    let disconnect_endpoint = APIpath + 'auth/disconnect-user/' + data.id;
    return axios.post(disconnect_endpoint)
    .then(response => response)
    .catch(error => {
      
      return Promise.reject(error);
      // throw (error);
    });
  }  

  static authAsBoardMember(data) {

    let endpoint = APIpath + 'auth/as-board-member/' + data.id;
    return axios.post(endpoint, data)
    .then(response => response )
    .catch(error => {
      // On jo rejectoitu?
      return Promise.reject(error);
      //throw (error);
    });
  }   

  static authAsShareholder(data) {
  
    let endpoint = APIpath + 'auth/as-shareholder/' + data.id;
    return axios.post(endpoint, data)
    .then(response => response)
    .catch(error => {
      return Promise.reject(error);
      // throw (error);
    });
  } 

  static requestAccessToken(credentials) {
  
    let login_endpoint = APIpath + 'auth/login';
    return axios.post(login_endpoint, credentials)
    .then(response => response)
    .catch(error => {
      return Promise.reject(error);
      // throw (error);
    });
  }

  static requestLoginEmail(email) {
  
    let endpoint = APIpath + 'auth/login/request-email';
    return axios.post(endpoint, email)
    .then(response => response)
    .catch(error => {
      return Promise.reject(error);
      // throw (error);
    });
  }  

  // Ei käytössä, siivoile pois. 
  // Teemu 8.11.2018
  static requestAccessTokenWithHash(hash) {
  
    let login_endpoint = APIpath + 'auth/login/hash';
    return axios.post(login_endpoint, hash)
    .then(response => response)
    .catch(error => {
      return Promise.reject(error);
      //throw (error);
    });
  }

  // Jos Access Token on vanhentunut, haetaan uusi token vanhalla
  static refreshAccessTokenApi(access_token) {
    // console.log("API - Freshing token", access_token);
    // TODO: ei vissii toimi refresh hommat.
    // Tällee jos lähettää, niin ei tunnista API päässä, palauttaa 500 virheineen.
    // Ainakin jos heittää ihan skeidaa tokeniksi.
    if (access_token) {
      setAuthorizationToken(access_token);
    }
    let refresh_endpoint = APIpath + 'auth/refresh';
    return axios.post(refresh_endpoint)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
        // throw (error);
    });
  }

  static checkAccessTokenApi(access_token) {
    setAuthorizationToken(access_token);
    let me_endpoint = APIpath + 'auth/me';
    return axios.get(me_endpoint)
      .then(response => response)
      .catch(error => {
        return Promise.reject(error);
        // throw (error);
    });
  }

  // Logout, invalidoi access_tokenin
  static logoutApi(access_token) {
    let logout_endpoint = APIpath + 'auth/logout';

    return axios.post(logout_endpoint)
      .then(response => response)
      .catch(error => {
        //console.log(" LOGOUT API ", error);
        return Promise.reject(error);
        // throw (error);
    });
  }

}
export default AuthApi;
