import React from 'react';
import {connect} from 'react-redux';
import { hideModal } from '../../actions/modalActions';

const Modal = (props) => {
  return (
    <div className="modal">
      <div className="card">
        <p>{props.dangerText}</p>

        <div className="buttons">
          <button onClick={props.onDangerClick}>
            Kyllä
          </button>
          <button onClick={() => props.dispatch(hideModal())}>
            Ei
          </button>
        </div>

      </div>
    </div>
  )
}

export default connect()(Modal)
