import axios from 'axios';
import { APIpath } from '../shared/util';

class ShareholderArchiveApi {

  static getArticles(currentUserID) {
    return axios.get(`${APIpath}users/${currentUserID}/shareholder-documents`).then(response => {
    //return axios.get(`${APIpath}users/123/bulletins`).then(response => {
      return response;
    }).catch(error => {
      // throw(error);
      return Promise.reject(error);
    });
  }
}
export default ShareholderArchiveApi;
