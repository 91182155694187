import * as actionTypes from '../actions/actionTypes.js'
import { createReducer } from '../store/Utilities'

const initialState = {
  articles : [],
  isLoading: false,
  error: null
}
export default createReducer(initialState, {
  [actionTypes.LOGOUT_DOCUMENTS](state) {
    return {
      ...state,
      articles: [],
      error : false
    }
  },    
  [actionTypes.LOAD_DOCUMENTS_ASYNC.PENDING](state) {
    return {
      ...state,
      isLoading: true
    }
  },
  [actionTypes.LOAD_DOCUMENTS_ASYNC.SUCCESS](state, action) {

    return {
      ...state,
      articles: action.documents,
      isLoading: false
    }
  },
  [actionTypes.LOAD_DOCUMENTS_ASYNC.ERROR](state) {
    return {
      ...state,
      isLoading: false
    }
  }
})
/*
const documentsReducer = (state = initialState, action) => {

  switch(action.type) {

    case actionTypes.LOAD_DOCUMENTS_FETCH:
      return {
        ...state,
        isLoading: true,
        error: false
      }
    case actionTypes.LOAD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        articles: action.payload.data.data,
        isLoading: false,
        error: false
      }
      case actionTypes.LOAD_DOCUMENTS_ERROR:
        return {
          ...state,
          isLoading: false,
          error: action.error
        }
    default:
      return state;
  }

};

export default documentsReducer;
*/