// https://hackernoon.com/replacing-redux-thunks-with-redux-sagas-4aa306854925
import myInfo from './myInfoSagas'
import document from './documentSagas'
import bulletin from './bulletinSagas'
import shareholder_archive from './shareholderArchiveSagas'
import board_archive from './boardArchiveSagas'
import news from './newsSagas'
import auth from './authSagas'
import app from './appSagas'
const sagas = [
    myInfo,
    bulletin,
    news,
    document,
    shareholder_archive,
    board_archive,
    auth,
    app
]
export const initSagas = (sagaMiddleware) => {
    sagas.forEach(sagaMiddleware.run.bind(sagaMiddleware))
}