import * as actionTypes from '../actions/actionTypes.js'
import {formInputChanged,
  //addFormInitialContactValues,
  resetForm } from '../shared/util'
import { toast } from 'react-toastify';

const initialState = {

  isSending: false,
  formSentSuccess: false,
  error: null,
  formIsValid : false,
  form : {
   
    hash_id : {
      elementtype : 'text',
      elementconfig : {
        type : 'text',
        placeholder : '',
        value : '',
      },
      label : 'Taloyhtiön tunniste',
      validation : {
        required : true
      },
      valid : false,
      touched : false,
    },
    
  },


}

const addUserFormReducer = (state = initialState, action) => {

  switch(action.type) {

    case actionTypes.ADD_USER_FORM_SET_INITIAL_VALUES :
       const initialForm = resetForm(state.form);
      // initialForm.hash_id.elementconfig.value = action.values.hash_id;
      return {
        ...state,
        form : initialForm
      };

    case actionTypes.ADD_USER_FORM_INPUT_CHANGED :
      const { form, formIsValid } = formInputChanged(state.form, action);
      return {
        ...state,
        form : form,
        formIsValid : formIsValid
      }

    case actionTypes.ADD_USER_FORM_SEND:
      return {
        ...state,
        isSending : true,
        sentError: false,
        formSentSuccess: false
      }
    case actionTypes.ADD_USER_FORM_SENT_SUCCESS:
      return {
        ...state,
        isSending : false,
        sentError : false,
        formSentSuccess: true,
        
      }
    case actionTypes.ADD_USER_FORM_SENT_ERROR:
      toast.error("Tunnisteella ei löytynyt taloyhtiötä.");
      return {
        ...state,
        isSending : false,
        sentError : action.error,
        formSentSuccess: false,
      }
    case actionTypes.ADD_USER_FORM_RESET :
      return {
        ...state,
        isSending: false,
        sentError : false,
        formSentSuccess: false,
        error: false,
        formIsValid : false,
        form: resetForm(state.form)
      }

    default:
      return state;
  }

};

export default addUserFormReducer;
