import * as actionTypes from './actionTypes';
import { createAction } from '../store/Utilities'
/* ei enää haetakkaa tääl actionissa (thunk), vaan sagassa, tsiigaa /sagas/documentSagas.js
import documentsApi from '../api/documentsApi';


export function loadDocuments(id) {

  // make async call to api, handle promise, dispatch action when promise is resolved
  return function(dispatch) {

    dispatch({type: actionTypes.LOAD_DOCUMENTS_FETCH});
    console.log("[documentActions.js] - Loading documents");
    return documentsApi.getArticles(id).then(payload => {

      dispatch({type: actionTypes.LOAD_DOCUMENTS_SUCCESS, payload});
    }).catch(error => {

      dispatch({type: actionTypes.LOAD_DOCUMENTS_ERROR, error});
      console.log("ERROR: ", error);
    });
  };
}
*/
/// https://hackernoon.com/replacing-redux-thunks-with-redux-sagas-4aa306854925
export const actions = {
  pending: () => createAction(actionTypes.LOAD_DOCUMENTS_ASYNC.PENDING),
  success: (documents) => createAction(actionTypes.LOAD_DOCUMENTS_ASYNC.SUCCESS, { documents }),
  error: (error) => createAction(actionTypes.LOAD_DOCUMENTS_ASYNC.ERROR, { error })
}