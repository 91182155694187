import React from 'react';

const DOMNotification = (props) => {

  let type = ""

  switch (props.type) {
    case ('success') : type = 'success'; break;
    case ('info') : type = 'info'; break;
    case ('warning'): type = 'warning'; break;
    case ('danger'): type = 'danger'; break;
    case ('error'): type = 'danger'; break;
    default: type = 'warning'; break;
  }

  let closeButton = props.clickEvent ? ( <button onClick={props.clickEvent} className="btn btn-primary">Sulje</button> ) : '';

  return (
    <div className="DOMNotification">
      <div className={`alert alert-${type}`}>
          <strong>{props.title}</strong>
          <div className="textcontent">{props.message}</div>
      </div>

      { closeButton }
    </div>
  )
};

export default DOMNotification;
