import {connect} from 'react-redux';
import React from 'react';
import DeleteMaintenanceRequestAttachmentModal from './DeleteMaintenanceRequestAttachmentModal'

const MODAL_COMPONENTS = {
  'DELETE_ATTACHMENT': DeleteMaintenanceRequestAttachmentModal
}

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return <span /> // after React v15 you can return null here
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]
  return <SpecificModal {...modalProps} />
}

export default connect(state => state.modal)(ModalRoot)
