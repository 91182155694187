import React, { Component } from 'react';
import { connect } from 'react-redux';
import { istonettiOauthLogoutURI, APIhost } from '../../shared/util';

import { Redirect, Link } from 'react-router-dom';

import { oauthLogoutPopup } from '../../actions/authActions';
class LogoutOauthView extends Component {

  timer 

  constructor(props) {
    super(props);
    this.receiveMessage = this.receiveMessage.bind(this);
  }

  openLogoutWindow = (url, name) => {
    url = istonettiOauthLogoutURI;
    name = 'istonetti-logout';
    
    // Pitäiskös tää koko roska olla jossain actionissa takana?
    // saagalla popuppi jne? 3.9.2020 -T
    this.props.dispatch(oauthLogoutPopup.pending());
     
     // remove any existing event listeners
     window.removeEventListener('message', this.receiveMessage);
  
     // window features
     const strWindowFeatures =
       'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
  
     if (windowObjectReference === null || windowObjectReference.closed) {
       /* if the pointer to the window object in memory does not exist
        or if such pointer exists but the window was closed */
       windowObjectReference = window.open(url, name, strWindowFeatures);
     } else if (previousUrl !== url) {
       /* if the resource to load is different,
        then we load it in the already opened secondary window and then
        we bring such window back on top/in front of its parent window. */
       windowObjectReference = window.open(url, name, strWindowFeatures);
       windowObjectReference.focus();
     } else {
       /* else the window reference must exist and the window
        is not closed; therefore, we can bring it back on top of any other
        window with the focus() method. There would be no need to re-create
        the window or to reload the referenced resource. */
       windowObjectReference.focus();
     }

     this.timer = setInterval(() => { 
          if(windowObjectReference.closed) {
              clearInterval(this.timer);
              
              // remove any existing event listeners
              window.removeEventListener('message', this.receiveMessage);
              this.props.dispatch(oauthLogoutPopup.error());
          }
      }, 500);
  
     // add the listener for receiving a message from the popup
     window.addEventListener('message', this.receiveMessage, false);
     // assign the previous URL
     previousUrl = url;
   };

   receiveMessage = event => {
    

    // Do we trust the sender of this message? (might be
    // different from what we originally opened, for example).
    if (event.origin !== APIhost) {
      return;
    }
    clearInterval(this.timer);

    window.removeEventListener('message', this.receiveMessage);

    // Tuleevaa stringi
    const status = event.data;
    if (status == 'logout') {
      this.props.dispatch(oauthLogoutPopup.success());
  
    } else {
      this.props.dispatch(oauthLogoutPopup.error('Popup origin error'));
    }
  };

  showHousingCompanyName = () => {

    if (this.props.housing_company_id) {
      return (
        <p>{this.props.housing_company_name}</p>
      );
    }

    return null;
  }

  
  componentDidMount = () => {
    this.openLogoutWindow()
  }

  render() {

    let renderHTML = (
      <div>
        { this.showHousingCompanyName() }
        <h1 className="page-title">Uloskirjautuminen käynnissä toisessa ikkunassa..</h1>
        <button className="btn btn-primary" onClick={this.openLogoutWindow}>Näytä ikkuna</button>
      </div>

    );

    if (! this.props.isAuthenticated) {
        renderHTML = (
        <Redirect to="/"/>
        );
    }

    return (
      <div>{renderHTML}</div>
    )
  }

  


}

let windowObjectReference = null;
let previousUrl = null;
  
  



const mapStateToProps = (state) => {

  return {
    redirect : state.auth.redirect,
    isAuthenticated : state.auth.isAuthenticated,
    error : state.auth.error,
    users : state.myinfo.myinfo.users,
    // Kirjautuessa kytketään taloyhtiöön.
    housing_company_id : state.myinfo.user_reference.id,
    housing_company_name : state.myinfo.user_reference.name
  }
};



export default connect(mapStateToProps)(LogoutOauthView);
