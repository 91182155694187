import React, { Component } from 'react';
import { connect } from 'react-redux';
import Preloader from '../UI/Preloader/Preloader'
import { GET_USER_REFERENCE_ASYNC } from '../../actions/actionTypes';

class IndividualToUser extends Component {

  render() {
    return (
      <Preloader/>
    )
  }

  // Tsekkaa urlista :hash, alkaa liittämään ittelle taloyhtiöksi.
  UNSAFE_componentWillMount() {

    this.props.dispatch( {type: GET_USER_REFERENCE_ASYNC.PENDING, hash_id : this.props.match.params.hash } );
    
    // Jos eijo kirjautuneena, ohjataan rekisteröitymiseen.
    console.log("[IndividualToUser.js] got hash ", this.props.match.params.hash);
    // this.props.dispatch(getAccessTokenWithHash(this.props.match.params.hash));
  
  }

}




const mapStateToProps = (state) => {
  
  return {
    isAuthenticated : state.auth.isAuthenticated,
    error : state.auth.error,
    users : state.myinfo.myinfo.users,
    myinfo : state.myinfo.myinfo
  }
};



export default connect(mapStateToProps)(IndividualToUser);
