import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';      

class CurrentUser extends Component {

  // TODO: linkitä users/Page_My_Users.js, mist voi vaihtaa userii
  switchUser = () => {

    if (this.props.isAuthenticated && this.props.hasCurrentUser) {

      return (
        <div className="company-name">
            <div>
            <Link to="/users/" className="no-decoration">
               {this.props.currentUser.company}
            </Link>
            </div>
        </div>
      );
    }
  }

  subtitle() {

    if (this.props.subtitle)
      return <b>{this.props.subtitle}</b>

    let parts = [];
    if (this.props.currentUser.address)
      parts.push(this.props.currentUser.address);

    if (this.props.currentUser.city) 
      parts.push(this.props.currentUser.city);
     
    return parts.join(', ');
  }

  render() {
    if (this.props.hasCurrentUser)
      return (
        <h1 className="current-house">
          {this.props.currentUser.company}
          <small>{this.subtitle()}</small>
        </h1>
      );

    return '';
  }
}
const MapStateToProps = (state) => {
    return {
      currentUser : state.myinfo.currentUser,
      hasCurrentUser : state.myinfo.currentUser.id ? true : false,
      isAuthenticated : state.auth.isAuthenticated
    }
  }
  export default connect(MapStateToProps)(CurrentUser);
