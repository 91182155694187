import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { REMOVE_FROM_MY_USERS_CLICKED } from '../../actions/actionTypes.js';

class Page_Settings extends Component {

  handleDisconnectUserClick = (e) => {
  this.props.dispatch( { type: REMOVE_FROM_MY_USERS_CLICKED } );
  }

  myInfoItems = () => {

    return [
      <li key="name"><label>Nimi</label>{this.props.myInfo.firstname} {this.props.myInfo.lastname}</li>,
      <li key="email"><label>Sähköpostiosoite</label>{this.props.myInfo.email}</li>,
      <li key="phone"><label>Puhelin</label>{this.props.myInfo.phone}</li>,
    ]
  }

  boardMemberButton = () => {

    if (this.props.currentUser.board_member) {
      return ( <p>Olet tunnistautunut hallituksen jäseneksi.</p> );
    } else {
      return (
        <p>
          <Link to="/as-board-member/" className="no-decoration">
            <button type="button" className="btn btn-primary">
                Tunnistaudu hallituksen jäseneksi
            </button>
          </Link> 
        </p>
      );  
    }  
  }

  shareholderButton = () => {

    if (this.props.currentUser.shareholder) {
      return ( <p>Olet tunnistautunut osakkaaksi.</p> );
    } else {
      return (
        <p>
          <Link to="/as-shareholder/" className="no-decoration">
          <button type="button" className="btn btn-primary">
              Tunnistaudu osakkaaksi
          </button>
          </Link> 
        </p>
      );  
    }
       
  }

  myInfo = () => {


    if (this.props.myInfo.id)
      return (
        <article className="card">
          <h2 className="article-title">Omat tiedot</h2>
          <ul className="list-unstyled label-value-list">
            { this.myInfoItems() }
          </ul>
          <Link to="/auth/logout/" className="no-decoration">
            <button type="button" className="btn btn-primary">
                  Kirjaudu ulos
            </button>
          </Link>
        </article>
      );

    return (
      <article className="card">
        <h2 className="article-title">Omat tiedot</h2>
        <Link to="/auth/login/" className="no-decoration">
            <button type="button" className="btn btn-primary">
                  Kirjaudu sisään
            </button>
        </Link>
      </article>
    );
  }

  companyInfo = () => {
    if (this.props.hasCurrentUser) {
      return (
        <article className="card">
          <h2 className="article-title">Taloyhtiön tiedot</h2>
          <ul className="list-unstyled label-value-list">
            { this.companyInfoItems() }
          </ul>

            { this.boardMemberButton() }
            { this.shareholderButton() }
            <button type="button" className="btn btn-primary" onClick={this.handleDisconnectUserClick}>
                En asu enää täällä
            </button>

        </article>      
      );
    } 
  }
  companyInfoItems = () => {
      return [
        <li key="company_name"><label>Nimi</label>{this.props.currentUser.company}</li>,
        <li key="company_address"><label>Osoite</label>{this.props.currentUser.address}<br/>{this.props.currentUser.zip} {this.props.currentUser.city}</li>,
        <li key="company_email"><label>Sähköpostiosoite</label>{this.props.currentUser.email}</li>,
        <li key="company_phone"><label>Puhelin</label>{this.props.currentUser.phone}</li>,
      ];
 
  }

  addCompany = () => {
    
      if (this.props.hasCurrentUser) {

        return (
          <article className="card">
            Voit liittää muitakin taloyhtiöitä itsellesi.
            <Link to="/oma/" className="no-decoration">
              <button type="button" className="btn btn-primary">
                  Liitä uusi taloyhtiö
              </button>
            </Link>
          </article>
        );
      } else {
        return (
          <article className="card">
            <h2 className="article-title">Oma taloyhtiö</h2>
            <Link to="/oma/" className="no-decoration">
              <button type="button" className="btn btn-primary">
                  Liitä taloyhtiösi
              </button>
            </Link>
          </article>
        );
      }
    
  }

  privacyPolicy = () => {

    if (this.props.hasCurrentUser) {
      return (
        <article className="card">
        <h2 className="article-title">Tietosuojaseloste</h2>
        <Link to="/privacy_policy/" className="no-decoration">
          <button type="button" className="btn btn-primary">
                Tietosuojaseloste
          </button>
        </Link>
      </article>    
      );
    }
  }

  render() {

    return (
        <div>
          <h1 className="page-title">Asetukset</h1>
          <section className="myInfo">

            { this.myInfo() }

            { this.companyInfo() }

            { this.addCompany() }
            
            { this.privacyPolicy() }

          </section>





        </div>

    )
  }


}

const mapStateToProps = (state) => {

  return {
    myInfo : state.myinfo.myinfo,
    currentUser : state.myinfo.currentUser,
    hasCurrentUser : state.myinfo.currentUser.id ? true : false
  }
};

export default connect(mapStateToProps)(Page_Settings);
