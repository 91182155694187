import axios from 'axios';
import { APIpath } from '../shared/util';

class BoardArchiveApi {

  static getArticles(currentUserID) {
    return axios.get(`${APIpath}users/${currentUserID}/board-documents`).then(response => {
    //return axios.get(`${APIpath}users/123/bulletins`).then(response => {
      return response;
    }).catch(error => {
      //throw(error);
      return Promise.reject(error);
    });
  }
}
export default BoardArchiveApi;
