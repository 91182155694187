import React from 'react';
import {connect} from 'react-redux';
import { deleteMaintenanceRequestFormAttachment } from '../../actions/maintenanceRequestFormActions';
import { hideModal } from '../../actions/modalActions';
import Modal from './Modal';

const DeleteMaintenanceRequestAttachmentModal = ({ object, dispatch }) => (
  <Modal
    dangerText={'Haluatko varmasti poistaa liitteen?'}
    isLoading={true}
    onDangerClick={() => {
        dispatch(deleteMaintenanceRequestFormAttachment(object.id));
        dispatch(hideModal());
      }
    }
  />
)

export default connect()(DeleteMaintenanceRequestAttachmentModal);
