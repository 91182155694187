/**
* Documents sagas
*/
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { actions } from '../actions/documentActions'
import * as actionTypes from '../actions/actionTypes'
import documentsApi from  '../api/documentsApi'
import { considerAsOldContent, getSeen } from '../shared/util'


const fetchDocuments = (id) => documentsApi.getArticles(id);
const getCurrentUser = (state) => state.myinfo.currentUser.id ? state.myinfo.currentUser : false;

function* getDocuments(action) {
  try {
    const currentUser = yield select(getCurrentUser);
    if (!currentUser) {
      throw String("No current user set!");
    }

    const response = yield call(fetchDocuments, currentUser.id)
    // TODO pitäis varmaan tuikkaa ens localstoragesta, jos on siellä. 
    // JA täs myö varmaan haluttasiin asettaa artikkelien status
    let seen = getSeen('document');
    let articles = response.data.data.map( (article) => {
      try {
        if (considerAsOldContent(article.created_at.date) || seen.find(seen_id => seen_id===article.id))
          article.status = 'seen';
        else
          article.status = 'new';
      } catch(e) {
        // Nuttin
      }
      return article;
    });

    yield put(actions.success(articles))
    
  } catch (e) {
    console.log(e)
    yield put(actions.error(e))
  }
}

function* refreshDocuments(action) {
  //console.log("SAGA refresh documents", action);
  yield put( { type: actionTypes.LOAD_DOCUMENTS_ASYNC.PENDING } );
}

export default function* () {
  yield all([
    takeLatest(actionTypes.LOAD_DOCUMENTS_ASYNC.PENDING, getDocuments),
    takeLatest(actionTypes.CURRENT_USER_CHANGED, refreshDocuments),
    //takeLatest(actionTypes.SET_CURRENT_USER.SUCCESS, refreshDocuments),
    takeLatest(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.SUCCESS, refreshDocuments),
    takeLatest(actionTypes.APP_CONNECTION_ONLINE, refreshDocuments),
  ])
}